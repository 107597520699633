import { Yup } from 'shared/lib'

const validPassword = /^(?=.*[0-9])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*()]{6,32}$/
// Schemas:

export const changePasswordSchema = Yup.object().shape({
   password: Yup.string().matches(validPassword, 'validation.passwordPattern').required(),
   confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref('password'), null], 'validation.confirmPassword'),
})
