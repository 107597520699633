export default [
   {
      subscription: 'BASIC',
      price: 165,
      caption: 'monthlyLabel',
      lists: [
         { text: 'basic.text' },
         { text: 'connectText' },
         { text: 'implementationText' },
         { text: 'technicalText' },
      ],
      labelAction: 'buyButton',
   },
   {
      subscription: 'PRO',
      price: 330,
      caption: 'monthlyLabel',
      lists: [{ text: 'pro.text' }, { text: 'connectText' }, { text: 'implementationText' }, { text: 'technicalText' }],
      labelAction: 'buyButton',
   },
   {
      subscription: 'ULTRA',
      price: 495,
      caption: 'monthlyLabel',
      lists: [
         { text: 'ultra.text1' },
         { text: 'ultra.text2' },
         { text: 'connectText' },
         { text: 'implementationText' },
         { text: 'technicalText' },
      ],
      labelAction: 'buyButton',
   },
]
