import { styled, Theme } from '@mui/material'
import Card from '@mui/material/Card'

const DefaultCorner = styled('div')({
   width: 35,
   height: 35,
   position: 'absolute',
   transition: '400ms',
})

export const EditCorner = styled(DefaultCorner)(({ theme }) => ({
   paddingTop: 8,
   paddingLeft: 8,
   top: 155,
   left: 155,
   cursor: 'pointer',
   borderLeft: '0px solid grey',
   borderTop: '0px solid grey',
   borderTopLeftRadius: '75%',
   boxShadow: '0 0 0.5em grey',
   '&:hover': {
      boxShadow: `0 0 1em ${theme.palette.primary.main}`,
      color: `${theme.palette.primary.main}`,
   },
}))

export const StyledCard = styled(Card)(({ theme }) => ({
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
   position: 'relative',
   width: 160,
   height: 160,
   borderRadius: 5,
   '&:hover': {
      boxShadow: `0 0 1.5em ${theme.palette.primary.dark}`,
      '& > div': {
         '&:nth-of-type(2)': {
            left: 125,
            top: 0,
         },
         '&:nth-of-type(3)': {
            left: 0,
            top: 125,
         },
         '&:nth-of-type(4)': {
            left: 125,
            top: 125,
         },
      },
   },
}))

export const StatusCorner = styled(DefaultCorner)(({ theme, pendingcoop }: { theme?: Theme; pendingcoop: string }) => ({
   top: 0,
   borderRight: '0px solid green',
   borderBottom: '0px solid green',
   borderBottomRightRadius: '75%',
   boxShadow: pendingcoop === 'true' ? `0 0 0.5em ${theme?.palette.primary.dark}` : '0 0 0.5em green',
   left: 0,
   '&:hover': {
      boxShadow: pendingcoop === 'true' ? `0 0 1.2em ${theme?.palette.primary.dark}` : '0 0 1.2em green',
   },
}))

export const DeleteCorner = styled(DefaultCorner)({
   top: -30,
   left: 155,
   cursor: 'pointer',
   paddingTop: 4,
   paddingLeft: 9,
   borderRight: '0px solid grey',
   borderBottom: '0px solid grey',
   borderBottomLeftRadius: '75%',
   boxShadow: '0 0 0.5em grey',
   transition: '400ms',
   '&:hover': {
      boxShadow: '0 0 1.2em red',
      color: 'red',
   },
})

export const EditTimesCorner = styled(DefaultCorner)(({ theme }) => ({
   top: 155,
   left: -30,
   paddingTop: 5,
   paddingLeft: 3,
   cursor: 'pointer',
   borderRight: '0px solid grey',
   borderTop: '0px solid grey',
   borderTopRightRadius: '75%',
   boxShadow: '0 0 0.5em grey',
   '&:hover': {
      boxShadow: `0 0 1em ${theme.palette.primary.main}`,
      color: `${theme.palette.primary.main}`,
   },
}))

export const StyledImg = styled('img')({
   height: 25,
})
