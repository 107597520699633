import React, { useEffect, useState } from 'react'
import { FormikProvider, Form, useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

import { LocationDetailsType, LocationType, SaveLocationDetailsPayload } from 'shared/store/useLocations/types'
import { useDataLocationsMutation } from 'shared/store/useLocations'
import InputField from 'shared/components/InputField'
import CustomSnackbar from 'shared/components/CustomSnackbar'

import { locationDetailsSchema } from './schema'

interface Props {
   values?: LocationType
}

export default function LocationDetailsform({ values }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'LOCATIONS'])
   const [displaySnackbar, setDisplaySnackbar] = useState(false)
   const { mutate, isSuccess, isError: isMutationError } = useDataLocationsMutation()
   const formik = useFormik<LocationDetailsType>({
      initialValues: {
         uuid: '',
         locationName: '',
         street: '',
         postCode: '',
         city: '',
         houseNumber: '',
         country: '',
         shippingStreet: '',
         shippingPostCode: '',
         shippingCity: '',
         shippingHouseNumber: '',
      },
      validationSchema: locationDetailsSchema,
      onSubmit: handleSubmit,
   })
   const { setValues } = formik
   function handleSubmit() {
      const newSettings: SaveLocationDetailsPayload = {
         uuid: formik.values.uuid,
         city: formik.values.city,
         zipCode: formik.values.postCode,
         street: formik.values.street,
         houseNumber: formik.values.houseNumber,
         country: formik.values.country,
         locationName: formik.values.locationName,
      }
      mutate(newSettings, {
         onSettled: () => setDisplaySnackbar(true),
      })
   }

   useEffect(() => {
      if (values) {
         setValues({
            ...values,
         })
      }
   }, [values])

   return (
      <FormikProvider value={formik}>
         <Form>
            <Stack spacing={{ xs: 2, md: 3 }}>
               <Typography
                  variant="body1"
                  sx={{ fontWeight: 'bold', color: 'text.secondary', textTransform: 'uppercase' }}
               >
                  {t('LOCATIONS:locationDetailsLabel')}
               </Typography>
               <Stack sx={{ pl: 3 }} direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 3 }}>
                  <InputField
                     name="locationName"
                     variant="standard"
                     disabled
                     required
                     label={t('TRANSLATION:formFields.locationName')}
                  />
                  <InputField name="street" variant="standard" required label={t('TRANSLATION:formFields.street')} />
                  <InputField
                     name="houseNumber"
                     variant="standard"
                     required
                     label={t('TRANSLATION:formFields.houseNumber')}
                  />
               </Stack>
               <Stack sx={{ pl: 3 }} direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 3 }}>
                  <InputField
                     name="postCode"
                     variant="standard"
                     required
                     label={t('TRANSLATION:formFields.postCode')}
                  />
                  <InputField name="city" variant="standard" required label={t('TRANSLATION:formFields.city')} />
                  <InputField
                     name="country"
                     variant="standard"
                     required
                     disabled
                     label={t('TRANSLATION:formFields.country')}
                  />
               </Stack>
            </Stack>
            <Typography
               variant="body1"
               sx={{ fontWeight: 'bold', color: 'text.secondary', textTransform: 'uppercase', mt: 3, mb: 2 }}
            >
               {t('LOCATIONS:shippingInformationsTitle')}
            </Typography>
            <Stack sx={{ pl: 3 }} direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 3 }}>
               <InputField
                  name="shippingStreet"
                  variant="standard"
                  required
                  label={t('TRANSLATION:formFields.street')}
               />
               <InputField
                  name="shippingHouseNumber"
                  variant="standard"
                  required
                  label={t('TRANSLATION:formFields.houseNumber')}
               />
               <InputField name="shippingCity" variant="standard" required label={t('TRANSLATION:formFields.city')} />
               <InputField
                  name="shippingPostCode"
                  variant="standard"
                  required
                  label={t('TRANSLATION:formFields.postCode')}
               />
            </Stack>

            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
               <Button onClick={() => handleSubmit()} sx={{ color: 'white' }} variant="contained">
                  {t('TRANSLATION:save')}
               </Button>
            </Box>
         </Form>
         <CustomSnackbar
            open={displaySnackbar}
            alertDetails={{
               alertType: isSuccess ? 'success' : 'error',
               alertMessage: isMutationError ? t('TRANSLATION:failedSave') : t('TRANSLATION:successSave'),
            }}
            handleClose={() => setDisplaySnackbar(false)}
         />
      </FormikProvider>
   )
}
