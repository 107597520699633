import isEqual from 'lodash/isEqual'
import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'

import { fetchSettingsData, saveSettingsData } from './services'
import { StateType, UpdateSettingsPayloadType, UpdateSettingsResult } from './types'

async function getSettingsData() {
   const request = await fetchSettingsData().then((response) => ({
         ...response.preferences,
         displayMinPopup: response.preferences.displayMinPopup === 'true',
      }))

   return request
}

export function useSettingsQuery(): UseQueryResult<StateType> {
   return useQuery('userSettings', getSettingsData)
}

export function useSettingsMutation() {
   const queryClient = useQueryClient()
   const initialData = queryClient.getQueryData<StateType>('userSettings')

   const initialPayload = initialData
      ? {
           preferences: {
              displayMinPopup: initialData.displayMinPopup,
              language: initialData.language,
              markup: initialData.markup,
              priceType: initialData.priceType,
              time: initialData.time,
           },
        }
      : {}

   return useMutation<UpdateSettingsResult, unknown, UpdateSettingsPayloadType, unknown>(
      (payload) => {
         if (isEqual(initialPayload, payload)) {
            throw new Error('nothing is changed')
         } else {
            return saveSettingsData(payload)
         }
      },
      {
         onSuccess: (data) => {
            if (data.status === 200) {
               queryClient.invalidateQueries('userSettings')
            }
         },
      }
   )
}
