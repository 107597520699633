import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

import RegisterForm from './components/RegisterForm'
import { en, pl } from './locales'

const RootStyle = styled('div')(({ theme }) => ({
   [theme.breakpoints.up('md')]: {
      display: 'flex',
   },
}))

const SectionStyle = styled(Card)({
   width: '100%',
   maxWidth: 464,
   margin: '40px',
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'center',
})

const ContentStyle = styled('div')({
   maxWidth: 580,
   marginLeft: 'auto',
   marginRight: 'auto',
   display: 'flex',
   justifyContent: 'space-between',
   flexDirection: 'column',
   padding: '50px 0',
})

export default function Register() {
   const theme = useTheme()
   const { t, i18n } = useTranslation('REGISTER')
   i18n.addResourceBundle('pl-PL', 'REGISTER', pl)
   i18n.addResourceBundle('en-US', 'REGISTER', en)

   return (
      <RootStyle title="Register">
         <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 2, mb: 5 }}>
               Get started with Motorro!
            </Typography>
            <img alt="register" src="http://localhost:8000/static/illustrations/illustration_register.png" />
         </SectionStyle>
         <Container>
            <ContentStyle>
               <Box sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ flexGrow: 1 }}>
                     <Typography variant="h4" gutterBottom>
                        {t('mainSite.registerTitle')}
                     </Typography>
                  </Box>
               </Box>
               <Box>
                  <RegisterForm />
               </Box>
               <Box>
                  <Typography variant="body2" align="center" sx={{ color: 'text.secondary' }}>
                     {t('mainSite.byRegisteringTitle')}&nbsp;
                     <RouterLink
                        style={{
                           textDecoration: 'none',
                           color: theme.palette.primary.dark,
                        }}
                        to="/terms-of-use"
                     >
                        {t('mainSite.termsOfService')}
                     </RouterLink>
                     &nbsp;{t('mainSite.and')}&nbsp;
                     <RouterLink
                        style={{
                           textDecoration: 'none',
                           color: theme.palette.primary.dark,
                        }}
                        to="/privacy-policy"
                     >
                        {t('mainSite.privacyPolicy')}
                     </RouterLink>
                     .
                  </Typography>
                  <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
                     {t('mainSite.backToLoginTitle')}&nbsp;
                     <RouterLink
                        style={{
                           textDecoration: 'none',
                           color: theme.palette.primary.dark,
                        }}
                        to="/"
                     >
                        {t('mainSite.loginButton')}
                     </RouterLink>
                  </Typography>
               </Box>
            </ContentStyle>
         </Container>
      </RootStyle>
   )
}
