import React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'

import { RootStyle, StyledLink, StyledLine, StyledContainer, StyledLi } from './styles'
import { en, pl } from './locales'

export default function TermsOfUse() {
   const { t, i18n } = useTranslation('TERMSOFUSE')
   i18n.addResourceBundle('pl-PL', 'TERMSOFUSE', pl)
   i18n.addResourceBundle('en-US', 'TERMSOFUSE', en)
   return (
      <RootStyle>
         <Typography variant="h4">{t('title')} </Typography>
         <StyledLine />
         <Typography sx={{ mt: '20px' }} variant="h6">
            {t('definitions.label')}
         </Typography>
         <StyledContainer>
            <Typography variant="body1">
               {t('definitions.part_1')}
               <StyledLink href="mailto:kontakt@motorro.pl"> kontakt@motorro.pl</StyledLink>
               {t('definitions.part_1_ad')}
            </Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">{t('definitions.part_2')}</Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">{t('definitions.part_3')}</Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">{t('definitions.part_4')}</Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">{t('definitions.part_5')}</Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">{t('definitions.part_6')}</Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">{t('definitions.part_7')}</Typography>
         </StyledContainer>
         <Typography sx={{ mt: '30px' }} variant="h6">
            {t('generalProvisions.label')}
         </Typography>
         <StyledContainer>
            <Typography variant="body1">{t('generalProvisions.part_1')}</Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">{t('generalProvisions.part_2')}</Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">{t('generalProvisions.part_3')}</Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">{t('generalProvisions.part_4')}</Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">{t('generalProvisions.part_5')}</Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">{t('generalProvisions.part_6')}</Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">{t('generalProvisions.part_7')}</Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">
               {t('generalProvisions.part_8_label')}
               <ul>
                  <StyledLi>{t('generalProvisions.part_8_a')}</StyledLi>
                  <StyledLi>{t('generalProvisions.part_8_b')}</StyledLi>
                  <StyledLi>{t('generalProvisions.part_8_c')}</StyledLi>
                  <StyledLi>{t('generalProvisions.part_8_d')}</StyledLi>
               </ul>
               <Typography variant="body1">{t('generalProvisions.part_8_ad')}</Typography>
            </Typography>
         </StyledContainer>
         <Typography sx={{ mt: '20px' }} variant="h6">
            {t('modeOfService.label')}
         </Typography>
         <StyledContainer>
            <Typography variant="body1">{t('modeOfService.part_1')}</Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">{t('modeOfService.part_2')}</Typography>
         </StyledContainer>
         <Typography sx={{ mt: '20px' }} variant="h6">
            {t('usingTheService.label')}
         </Typography>
         <StyledContainer>
            <Typography variant="body1">{t('usingTheService.part_1')}</Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">{t('usingTheService.part_2')}</Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">{t('usingTheService.part_3')}</Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">{t('usingTheService.part_4')}</Typography>
         </StyledContainer>
         <Typography sx={{ mt: '20px' }} variant="h6">
            {t('usingYourAccount.label')}
         </Typography>
         <StyledContainer>
            <Typography variant="body1">
               {t('usingYourAccount.part_1_label')}
               <ul>
                  <StyledLi>{t('usingYourAccount.part_1_a')}</StyledLi>
                  <StyledLi>{t('usingYourAccount.part_1_b')}</StyledLi>
                  <StyledLi>{t('usingYourAccount.part_1_c')}</StyledLi>
                  <StyledLi>{t('usingYourAccount.part_1_d')}</StyledLi>
                  <StyledLi>{t('usingYourAccount.part_1_e')}</StyledLi>
               </ul>
               <Typography variant="body1">{t('usingYourAccount.part_1_ad_1')}</Typography>
               <Typography variant="body1">{t('usingYourAccount.part_1_ad_2')}</Typography>
               <Typography variant="body1">{t('usingYourAccount.part_1_ad_3')}</Typography>
               <Typography variant="body1">{t('usingYourAccount.part_1_ad_4')}</Typography>
            </Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">
               {t('usingYourAccount.part_2_label')}
               <ul>
                  <StyledLi>{t('usingYourAccount.part_2_a')}</StyledLi>
                  <StyledLi>{t('usingYourAccount.part_2_b')}</StyledLi>
                  <StyledLi>{t('usingYourAccount.part_2_c')}</StyledLi>
                  <StyledLi>{t('usingYourAccount.part_2_d')}</StyledLi>
               </ul>
            </Typography>
         </StyledContainer>
         <Typography sx={{ mt: '20px' }} variant="h6">
            {t('wholesaleCompanies.label')}
         </Typography>
         <StyledContainer>
            <Typography variant="body1">{t('wholesaleCompanies.part_1')}</Typography>

            <ul>
               <li>{t('wholesaleCompanies.part_2')}</li>
               <li>{t('wholesaleCompanies.part_3')}</li>
               <li>{t('wholesaleCompanies.part_4')}</li>
            </ul>
         </StyledContainer>
         <Typography sx={{ mt: '20px' }} variant="h6">
            {t('usingANewsletter.label')}
         </Typography>
         <StyledContainer>
            <Typography variant="body1">{t('usingANewsletter.part_1')}</Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">{t('usingANewsletter.part_2')}</Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">{t('usingANewsletter.part_3')}</Typography>
         </StyledContainer>
         <Typography sx={{ mt: '20px' }} variant="h6">
            {t('responsibility.label')}
         </Typography>
         <StyledContainer>
            <Typography variant="body1">{t('responsibility.content')}</Typography>
         </StyledContainer>
         <Typography sx={{ mt: '20px' }} variant="h6">
            {t('protectionOfPersonalData.label')}
         </Typography>
         <StyledContainer>
            <Typography variant="body1">{t('protectionOfPersonalData.part_1')}</Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">{t('protectionOfPersonalData.part_2')}</Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">{t('protectionOfPersonalData.part_3')}</Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">{t('protectionOfPersonalData.part_4')}</Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">{t('protectionOfPersonalData.part_5')}</Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">{t('protectionOfPersonalData.part_6')}</Typography>
         </StyledContainer>
         <Typography sx={{ mt: '20px' }} variant="h6">
            {t('cookies.label')}
         </Typography>
         <StyledContainer>
            <Typography variant="body1">{t('cookies.part_1')}</Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">{t('cookies.part_2')}</Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">{t('cookies.part_3')}</Typography>
         </StyledContainer>
         <Typography sx={{ mt: '20px' }} variant="h6">
            {t('timeOfTerminatingTheRegulations.label')}
         </Typography>
         <StyledContainer>
            <Typography variant="body1">{t('timeOfTerminatingTheRegulations.part_1')}</Typography>
         </StyledContainer>
         <StyledContainer>
            <Typography variant="body1">{t('timeOfTerminatingTheRegulations.part_2')}</Typography>
         </StyledContainer>
         <Typography sx={{ mt: '20px' }} variant="h6">
            {t('finalProvisions.label')}
         </Typography>
         <StyledContainer>
            <Typography variant="body1">{t('finalProvisions.content')}</Typography>
         </StyledContainer>
      </RootStyle>
   )
}
