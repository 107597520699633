import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'

import { LocationType } from 'shared/store/useLocations/types'

import AdminDetailsForm from '../AdminDetailsForm'
import LocationDetailsForm from '../LocationDetailsForm'

interface Props {
   openModal: () => void
   values?: LocationType
}
export default function AdminForm({ openModal, values }: Props) {
   return (
      <Grid item xs={12} md={9}>
         <Card sx={{ p: 3, m: 2, mt: 3 }}>
            <LocationDetailsForm values={values} />
            <AdminDetailsForm openModal={openModal} values={values} />
         </Card>
      </Grid>
   )
}
