import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useFormikContext } from 'formik'
import Alert from '@mui/material/Alert'

import { httpClient } from 'shared/lib'
import { isAxiosError } from 'shared/utils/helpers'
import InputField from '../InputField'

type SmsCodeError = {
   timestamp: number
   status: number
   error: string
   message: string
}

export function ThirdStep() {
   const { t } = useTranslation(['TRANSLATION', 'REGISTER'])
   const { values } = useFormikContext<RegisterInitialValues>()
   const [isLoading, setIsLoading] = useState(false)
   const [smsCodeError, setSmsCodeError] = useState('')
   const [smsCodeSuccess, setSmsCodeSuccess] = useState('')

   async function getSmsCode() {
      const phoneNumber = values.telephoneNumber
      setSmsCodeError('')
      setSmsCodeSuccess('')
      setIsLoading(true)
      try {
         await httpClient.post('sms/send', { phoneNumber })
         setSmsCodeSuccess(t('REGISTER:mainSite.smsCodeSuccess', { phone: phoneNumber }))
      } catch (error) {
         if (isAxiosError<SmsCodeError>(error)) {
            if (error.response?.data.status === 400) {
               setSmsCodeError(t('REGISTER:errors.phoneNumberNotFound'))
               // set smsCode error with wrong phone number
            } else {
               setSmsCodeError(t('GLOBALS.apiErrors.500'))
            }
         }
      } finally {
         setIsLoading(false)
      }
   }
   return (
      <Container>
         <Grid container justifyContent="space-between">
            <Grid item xs={12} sx={{ mb: 2 }}>
               <Typography component="h3" variant="subtitle1" align="center">
                  Krok 1
               </Typography>
               <Typography align="center">Stwórz hasło do aplikacji</Typography>
            </Grid>
            <Grid item xs={5}>
               <InputField type="password" label={t('TRANSLATION:formFields.password')} name="password" />
            </Grid>
            <Grid item xs={5}>
               <InputField type="password" label={t('TRANSLATION:formFields.confirmPassword')} name="confirmPassword" />
            </Grid>
         </Grid>
         <Grid justifyContent="center" container sx={{ my: 7 }}>
            <Grid item xs={12}>
               <Typography component="h3" variant="subtitle1" align="center">
                  Krok 2
               </Typography>
               <Typography align="center">Wyślij żądanie z prośbą o autoryzacje numeru telefonu</Typography>
            </Grid>
            <Grid item xs={5}>
               <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  sx={{ mt: 2, color: 'white' }}
                  onClick={getSmsCode}
                  disabled={isLoading}
               >
                  Pobierz kod
               </Button>
            </Grid>
            {smsCodeError && (
               <Alert severity="error" sx={{ mt: 2, textAlign: 'center' }}>
                  {smsCodeError}
               </Alert>
            )}
            {smsCodeSuccess && (
               <Alert severity="success" sx={{ mt: 2, textAlign: 'center' }}>
                  {smsCodeSuccess}
               </Alert>
            )}
         </Grid>
         <Grid justifyContent="center" container>
            <Grid item xs={12} sx={{ mb: 2 }}>
               <Typography component="h3" variant="subtitle1" align="center">
                  Krok 3
               </Typography>
               <Typography align="center">Wprowadź otrzymany kod i dokończ rejestrację</Typography>
            </Grid>
            <Grid item xs={5}>
               <InputField type="smsCode" label={t('TRANSLATION:formFields.smsCode')} name="smsCode" />
            </Grid>
         </Grid>
      </Container>
   )
}
