import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'

import Hidden from 'shared/components/Hidden'
import jwtImg from 'assets/images/ic_jwt.png'
import RemindPasswordForm from './components/RemindPasswordForm'
import HomepageSlider from './components/HomepageSlider'
import LoginForm from './components/LoginForm'
import { en, pl } from './locales'
import ProducersSlider from './components/ProducersSlider'

const RootStyle = styled('div')(({ theme }) => ({
   [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      minHeight: 'calc(100vh - 64px - 87px)',
      margin: 'auto',
   },
}))

const SectionStyle = styled(Card)(({ theme }) => ({
   flex: '1 1 40%',
   height: '500px',
   margin: theme.spacing(2, 0),
   position: 'relative',
}))

const ContentStyle = styled('div')(({ theme }) => ({
   maxWidth: 480,
   margin: 'auto',
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'center',
   padding: theme.spacing(12, 0),
}))

const StyledLink = styled('span')(({ theme }) => ({
   marginLeft: 5,
   textDecoration: 'none',
   color: theme.palette.primary.dark,
   cursor: 'pointer',
   '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.darker,
   },
}))

export default function Homepage() {
   const [remind, setRemind] = useState(false)
   const { t, i18n } = useTranslation('HOMEPAGE')
   i18n.addResourceBundle('pl-PL', 'HOMEPAGE', pl)
   i18n.addResourceBundle('en-US', 'HOMEPAGE', en)

   return (
      <Container maxWidth="xl">
         <RootStyle>
            <Hidden width="mdDown">
               <SectionStyle>
                  <HomepageSlider />
               </SectionStyle>
            </Hidden>

            <Container sx={{ flex: '1 1 60%', height: '100%' }}>
               <ContentStyle>
                  <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                     <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="h4" gutterBottom>
                           {remind ? t('remindForm.remindPassword') : t('signIn.title')}
                        </Typography>
                        {remind ? null : (
                           <Typography sx={{ color: 'text.secondary' }}>{t('signIn.subTitle')}</Typography>
                        )}
                     </Box>

                     <Tooltip title="Test">
                        <Box component="img" src={jwtImg} sx={{ width: 32, height: 32 }} />
                     </Tooltip>
                  </Stack>
                  {remind ? (
                     <div>
                        <RemindPasswordForm />
                        <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                           {t('HOMEPAGE:remindForm.haveAccount')}
                           <StyledLink onClick={() => setRemind(!remind)}>{t('HOMEPAGE:remindForm.login')}</StyledLink>
                        </Typography>
                     </div>
                  ) : (
                     <div>
                        <LoginForm />
                        <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                           {t('HOMEPAGE:loginForm.forgotPassword')}
                           <StyledLink onClick={() => setRemind(!remind)}>{t('HOMEPAGE:remindForm.remind')}</StyledLink>
                        </Typography>
                     </div>
                  )}
                  <ProducersSlider />
               </ContentStyle>
            </Container>
         </RootStyle>
      </Container>
   )
}
