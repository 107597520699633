import { httpClient } from 'shared/lib'
import { FetchAwardedProducersResults } from './types'

export function fetchAwardedProducers(): Promise<FetchAwardedProducersResults> {
   return httpClient
      .get('producers/awarded/')
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch((err) => {
         console.log(0, 'ERROR FROM fetchAwarderProducers()', err)
         throw new Error('apiErrors.apiProblem')
      })
}
