import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

import { useLocationsQuery } from 'shared/store/useLocations'
import { LocationType } from 'shared/store/useLocations/types'
import useWholesalers from 'shared/store/useWholesalers'
import { WholesalerType } from 'shared/store/useWholesalers/types'

import { pl, en } from './locales'
import Modal from './components/Modal'
import WholesalerCard from './components/WholesalerCard'
import LocationList from './components/LocationsList'
import { WholesalerActionType } from './types'
import NonCoopWholesalerCard from './components/NonCoopWholesalerCard'

const RootStyle = styled('div')(({ theme }) => ({
   [theme.breakpoints.up('md')]: {
      display: 'flex',
   },
}))

type ActiveWholeSaler = {
   data: WholesalerType
   type: WholesalerActionType
}

export default function Wholesalers() {
   const { data } = useLocationsQuery()
   const { i18n, t } = useTranslation('WHOLESALERS')
   i18n.addResourceBundle('pl-PL', 'WHOLESALERS', pl)
   i18n.addResourceBundle('en-US', 'WHOLESALERS', en)
   const [activeLocation, setActiveLocation] = useState<LocationType>()
   const [activeWholesaler, setActiveWholesaler] = useState<ActiveWholeSaler>()
   const { data: wholesalers } = useWholesalers(activeLocation?.uuid)

   function handleWholesalerChange(wholesalerId: string, type: WholesalerActionType) {
      const newActiveWholesaler = wholesalers?.find((item) => item.wholesaleUuid === wholesalerId)
      if (newActiveWholesaler) {
         setActiveWholesaler({ type, data: newActiveWholesaler })
      }
   }

   useEffect(() => {
      if (data) {
         setActiveLocation(data[0])
      }
   }, [data])

   const handleOnChangeLocation = (id: string) => {
      if (data && id !== activeLocation?.uuid) {
         const newActiveLocation = data.find((item) => item.uuid === id)
         setActiveLocation(newActiveLocation)
      }
   }

   return (
      <RootStyle>
         <Grid container spacing={2} sx={{ my: 1 }}>
            <LocationList list={data} active={activeLocation} onChangeLocation={handleOnChangeLocation} />
            <Grid item xs={12} md={8}>
               <Card sx={{ p: 4, m: 2, mt: 3 }}>
                  <Typography sx={{ fontWeight: 'normal', color: 'GrayText', textTransform: 'upperCase' }} variant="h6">
                     {t('WHOLESALERS:wholesalers')}
                  </Typography>

                  {wholesalers ? (
                     <Grid
                        container
                        spacing={{ xs: 5, lg: 2 }}
                        sx={{
                           paddingTop: 5,
                           marginLeft: { xs: -7, md: -7, lg: 'auto' },
                        }}
                     >
                        {wholesalers
                           .sort((a, b) =>
                              a.cooperation > b.cooperation || a.askCooperation > b.askCooperation ? -1 : 1
                           )
                           .map((wholesaler) =>
                              wholesaler.cooperation || wholesaler.askCooperation ? (
                                 <WholesalerCard
                                    key={wholesaler.wholesaleUuid}
                                    handleWholesaler={handleWholesalerChange}
                                    activeWholesaler={wholesaler}
                                 />
                              ) : (
                                 <NonCoopWholesalerCard
                                    key={wholesaler.wholesaleUuid}
                                    handleWholesaler={handleWholesalerChange}
                                    activeWholesaler={wholesaler}
                                 />
                              )
                           )}
                     </Grid>
                  ) : (
                     <div
                        style={{
                           width: '100%',
                           textAlign: 'center',
                        }}
                     >
                        <CircularProgress color="primary" />
                     </div>
                  )}
               </Card>
            </Grid>
         </Grid>
         {activeWholesaler && (
            <Modal
               open={!!activeWholesaler}
               data={activeWholesaler}
               handleClose={() => setActiveWholesaler(undefined)}
            />
         )}
      </RootStyle>
   )
}
