import * as Yup from 'yup'

Yup.setLocale({
   mixed: {
      required: 'TRANSLATION:validation.required',
   },

   string: {
      email: 'TRANSLATION:validation.email',
   },
})

export { Yup }
