import React from 'react'
import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import { styled, useTheme } from '@mui/material'

import { WholesalerType } from 'shared/store/useWholesalers/types'

import EditTimesModal from '../EditTimesModal'
import { WholesalerActionType } from '../../types'
import WholesalerInfoModal from '../WholesalerInfoModal'

const StyledDialogButton = styled(Button)(() => ({
   textTransform: 'uppercase',
   fontWeight: 'normal',
}))

interface Props {
   data: {
      data: WholesalerType
      type: WholesalerActionType
   }
   open: boolean
   handleClose: () => void
}

export default function Modal({ open, data: { data, type }, handleClose }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'WHOLESALERS'])
   const theme = useTheme()

   function renderContent() {
      switch (type) {
         case 'deleteWholesaler':
            return (
               <Typography variant="body1" sx={{ mt: 1, mb: 1, color: 'text.secondary' }}>
                  {t('WHOLESALERS:deleteWholesalerDialog')}
               </Typography>
            )
         case 'editTimes':
            return <EditTimesModal wholesalerId={data.wholesaleUuid} closeModal={handleClose} />
         case 'editWholesaler':
            return <WholesalerInfoModal wholesalerInfo={data} />
         case 'addWholesaler':
            return <WholesalerInfoModal wholesalerInfo={data} />
         default:
            return null
      }
   }

   return (
      <Dialog fullWidth={type === 'editWholesaler'} maxWidth="md" open={open} onClose={handleClose}>
         <DialogTitle>
            <Typography sx={{ color: theme.palette.primary.main, fontSize: 22 }}>
               {type === 'editWholesaler' || type === 'addWholesaler' ? data?.wholesaleName : t(`WHOLESALERS:${type}`)}
            </Typography>
         </DialogTitle>
         <DialogContent>{renderContent()}</DialogContent>
         {/* <DialogActions sx={{ mb: 1 }}>
            <StyledDialogButton onClick={handleClose}>
               {type === 'deleteWholesaler' ? t('TRANSLATION:no') : t('TRANSLATION:cancel')}
            </StyledDialogButton>
            <StyledDialogButton>
               {type === 'deleteWholesaler' ? t('TRANSLATION:yes') : t('TRANSLATION:save')}
            </StyledDialogButton>
         </DialogActions> */}
      </Dialog>
   )
}
