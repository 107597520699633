declare module '@mui/material/styles' {
   interface Theme {
      sizes: CustomSizes
   }

   interface ThemeOptions {
      sizes: CustomSizes
   }
}

interface CustomSizes {
   headerSm: number
}

const sizes: CustomSizes = {
   headerSm: 64,
}

export default sizes
