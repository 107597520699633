import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import routes from 'config/routes'

const openedMixin = (theme: Theme): CSSObject => ({
   width: 240,
   position: 'sticky',
   height: '100%',
   transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
   }),

   overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
   position: 'sticky',
   height: '100%',
   transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
   }),
   overflowX: 'hidden',
   width: `calc(${theme.spacing(7)} + 1px)`,
})

const DrawerHeader = styled('div')(({ theme }) => ({
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'flex-end',
   padding: theme.spacing(0, 1),

   // necessary for content to be below app bar

   ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {
   shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
   width: 240,
   flexShrink: 0,
   whiteSpace: 'nowrap',
   boxSizing: 'border-box',
   ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
   }),
   ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
   }),
}))

const StyledTextItem = styled(ListItemText)({
   transform: ' scale(0.9) ',
})

const MENU_GROUPS = [
   {
      name: 'cart',
      label: 'asideLabels.cart',
   },
   {
      name: 'messages',
      label: 'asideLabels.messages',
   },
   {
      name: 'settings',
      label: 'asideLabels.settings',
   },
]

export default function Sidebar() {
   const { t } = useTranslation('AUTHORIZED')
   const navigate = useNavigate()
   const location = useLocation()
   const theme = useTheme()
   const [open, setOpen] = React.useState(false)
   const handleDrawerOpen = () => {
      setOpen(true)
   }

   const handleDrawerClose = () => {
      setOpen(false)
   }

   return (
      <Box sx={{ display: 'flex' }}>
         <CssBaseline />

         <Drawer onMouseEnter={handleDrawerOpen} onMouseLeave={handleDrawerClose} variant="permanent" open={open}>
            <DrawerHeader>
               <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  sx={{
                     marginRight: 'auto',
                     marginLeft: 'auto',
                     marginBottom: '-24px',
                     ...(open && { display: 'none' }),
                  }}
               >
                  <MenuIcon />
               </IconButton>

               <IconButton onClick={handleDrawerClose} sx={{ ...(!open && { display: 'none' }) }}>
                  {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
               </IconButton>
            </DrawerHeader>
            {MENU_GROUPS.map((group) => (
               <React.Fragment key={group.name}>
                  <Typography
                     variant="body1"
                     style={{
                        color: theme.palette.primary.dark,
                        transition: '300ms',
                        marginLeft: `${!open ? '-100px' : '8px'}`,
                     }}
                  >
                     {t(group.label)}
                  </Typography>

                  <Divider />

                  <List>
                     {routes
                        .filter((route) => route.group === group.name)
                        .map((route) => {
                           const isActive = location.pathname.includes(route.path)
                           return (
                              <ListItem
                                 key={route.name}
                                 onClick={() => navigate({ pathname: route.path })}
                                 selected={isActive}
                                 sx={{
                                    color: isActive ? theme.palette.primary.dark : null,
                                    borderRight: isActive ? '3px solid ' : null,
                                    borderRightColor: theme.palette.primary.dark,
                                    '&:hover': {
                                       color: theme.palette.primary.dark,
                                       backgroundColor: theme.palette.primary.light,
                                    },
                                 }}
                                 button
                              >
                                 <ListItemIcon>{route.icon}</ListItemIcon>
                                 <StyledTextItem primary={t(route.name)} />
                              </ListItem>
                           )
                        })}
                  </List>
               </React.Fragment>
            ))}
         </Drawer>
      </Box>
   )
}
