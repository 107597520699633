import { styled } from '@mui/material'

export const RootStyle = styled('div')(({ theme }) => ({
   width: 154,
   height: 144,
   margin: '0 auto',
   display: 'flex',
   borderRadius: '50%',
   padding: theme.spacing(1),
   border: `1px dashed ${theme.palette.grey[500_32]}`,
}))

export const DropZoneStyle = styled('div')(({ theme }) => ({
   width: '100%',
   height: '100%',
   borderRadius: '50%',
   justifyContent: 'center',
   alignItems: 'center',
   alignContent: 'center',
   '&:hover': {
      cursor: 'pointer',
      opacity: 0.72,
   },
   transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
   }),
}))

export const StyledImage = styled('img')({
   display: 'flex',
   height: '100%',
   width: '100%',
   justifyContent: 'center',
   alignItems: 'center',
   alignContent: 'center',
   position: 'relative',
   bottom: 125,
   zIndex: -1,
   borderRadius: '50%',
})
export const PlaceholderStyle = styled('div')(({ theme }) => ({
   height: '100%',
   width: '100%',
   borderRadius: '50%',
   position: 'relative',
   opacity: 0,
   color: theme.palette.text.secondary,
   backgroundColor: theme.palette.background.neutral,
   transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
   }),
   '&:hover': { opacity: 0.72 },
}))
export const StyledInput = styled('input')({
   height: '100%',
   width: '100%',
   borderRadius: '50%',
   cursor: 'pointer',
   opacity: 0,
})
