import React from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import pricingPlans from 'shared/data/PricingPlans'
import PricingPlanCard from './components/PricingPlanCard'
import { en, pl } from './locales'

const RootStyle = styled('div')(({ theme }) => ({
   [theme.breakpoints.up('md')]: {
      display: 'flex',
   },
}))
export default function Pricing() {
   const { t, i18n } = useTranslation('PRICING')
   i18n.addResourceBundle('pl-PL', 'PRICING', pl)
   i18n.addResourceBundle('en-US', 'PRICING', en)
   return (
      <RootStyle>
         <Container maxWidth="lg">
            <Typography sx={{ mt: 2, mb: -2, color: 'primary.main' }} variant="h2" align="center" paragraph>
               {t('pricingLabel')}
            </Typography>
            <Grid sx={{ my: 1 }} container spacing={3}>
               {pricingPlans.map((card) => (
                  <Grid item xs={12} md={4} key={card.subscription}>
                     <PricingPlanCard card={card} />
                  </Grid>
               ))}
            </Grid>
            <Box sx={{ mb: 4, mt: 3 }}>
               <Stack direction="row" alignItems="center" justifyContent="flex-start">
                  <TextField variant="standard" label={t('discountLabel')} />
                  <Button sx={{ color: 'white', fontSize: 13, marginLeft: 2, marginTop: 1.5 }} variant="contained">
                     {t('enterButtonLabel')}
                  </Button>
               </Stack>
            </Box>
         </Container>
      </RootStyle>
   )
}
