import React from 'react'

import { Routes, Route } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import RegisterPage from './views/Register'

import TermsOfUse from './views/TermsOfUse'

import Layout from './components/Layout'

import Homepage from './views/Homepage'

import { en, pl } from './locales'
import PrivacyPolicy from './views/PrivacyPolicy'

export default function Unauthorized() {
   const { i18n } = useTranslation(['UNAUTHORIZED', 'GLOBALS'])

   i18n.addResourceBundle('pl-PL', 'UNAUTHORIZED', pl)
   i18n.addResourceBundle('en-US', 'UNAUTHORIZED', en)

   return (
      <Routes>
         <Route path="/" element={<Layout />}>
            <Route index element={<Homepage />} />
            <Route path="register" element={<RegisterPage />} />
            <Route path="terms-of-use" element={<TermsOfUse />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />\
         </Route>
      </Routes>
   )
}
