import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import Badge from '@mui/material/Badge'
import IconButton from '@mui/material/IconButton'
import Popover from '@mui/material/Popover'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import NotificationsIcon from '@mui/icons-material/Notifications'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import LogoutIcon from '@mui/icons-material/Logout'
import { useTheme } from '@mui/material/styles'

import SearchProduct from 'shared/components/SearchProduct'
import LanguagePopover from 'shared/components/LanguagePopover'
import useLogin from 'shared/store/useLogin'
import logo from 'assets/images/logo.png'
import { RootStyle, StyledLogo } from './styles'

export default function Header() {
   const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
   const { t } = useTranslation('AUTHORIZED')
   const theme = useTheme()
   const { logout, user } = useLogin()

   const MENU_OPTIONS = [
      {
         label: t('header.menuItems.homePage'),
         linkTo: '/dashboard',
         icon: <HomeOutlinedIcon color="primary" />,
      },

      {
         label: t('header.menuItems.settings'),
         linkTo: 'settings',
         icon: <SettingsOutlinedIcon color="primary" />,
      },
   ]

   return (
      <RootStyle>
         <Container style={{ minWidth: '100%' }}>
            <Stack direction="row" alignItems="center">
               <RouterLink
                  style={{
                     marginRight: 1,
                     marginLeft: '40px',
                     textDecoration: 'none',
                     color: theme.palette.primary.dark,
                  }}
                  to="/"
               >
                  <StyledLogo src={logo} alt="logo" />
               </RouterLink>{' '}
               <Box sx={{ flexGrow: 1 }} />
               <SearchProduct />
               <Box sx={{ flexGrow: 1 }} />
               <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                  <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                     <AccountCircleOutlinedIcon sx={{ fontSize: 30 }} color={anchorEl ? 'primary' : 'inherit'} />
                  </IconButton>

                  <Popover
                     anchorEl={anchorEl}
                     open={!!anchorEl}
                     onClose={() => setAnchorEl(null)}
                     sx={{ mt: 4 }}
                     anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                     }}
                  >
                     <Box sx={{ my: 1.5, px: 2.5 }}>
                        <Typography variant="body1" noWrap>
                           {user.user?.name}
                        </Typography>

                        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                           Lokalizacja Główna
                        </Typography>
                     </Box>
                     <Divider sx={{ my: 1 }} />
                     {MENU_OPTIONS.map((option) => (
                        <MenuItem
                           key={option.label}
                           to={option.linkTo}
                           component={RouterLink}
                           onClick={() => setAnchorEl(null)}
                           sx={{ typography: 'body2', py: 1 }}
                        >
                           {option.icon}

                           <Box
                              sx={{
                                 width: 17,

                                 height: 24,
                              }}
                           />

                           {option.label}
                        </MenuItem>
                     ))}
                     <Divider sx={{ my: 1 }} />
                     <MenuItem sx={{ typography: 'body2', py: 1 }} onClick={() => logout()}>
                        <LogoutIcon color="primary" />

                        <Box
                           sx={{
                              width: 17,

                              height: 24,
                           }}
                        />

                        {t('header.menuItems.logout')}
                     </MenuItem>
                  </Popover>
                  <IconButton href="notifications">
                     <Badge
                        badgeContent={105}
                        color="primary"
                        sx={{ '& .MuiBadge-badge': { color: 'white', fontSize: 12 } }}
                        max={99}
                     >
                        <NotificationsIcon sx={{ fontSize: 28 }} />
                     </Badge>
                  </IconButton>
                  <LanguagePopover />
               </Stack>
            </Stack>
         </Container>
      </RootStyle>
   )
}
