import { Yup } from 'shared/lib'

const validName = /^([a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄ'-]\s?)*([^\s\d][a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄ])$/
const validTelephoneNumber = /^[0-9]{9}$/
const validTaxID = /^\d{10}$/
const validCompanyName = /^([a-zA-Z0-9żźćńółęąśŻŹŚŁĆŃÓĘĄ'",-][.]?[\s]?)*([a-zA-Z0-9żźćńółęąśŻŹŚŁĆŃÓĘĄ]\.?)$/
const validPostCode = /^[0-9]{2}-[0-9]{3}$/
const validCity = /^([a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄ",-][.]?[\s]?)*([^\d][a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄ",-]\.?)$/

const schema = Yup.object().shape(
   {
      name: Yup.string().min(3).max(32).matches(validName).required(),
      surname: Yup.string().min(3).max(32).matches(validName).required(),
      phoneNumber: Yup.string().matches(validTelephoneNumber, 'validation.telephoneNumberPattern').required(),
      taxID: Yup.string().required().matches(validTaxID),
      companyName: Yup.string().max(128).matches(validCompanyName).required(),
      street: Yup.string().max(100).required(),
      houseNumber: Yup.string().max(15).required(),
      zipCode: Yup.string().matches(validPostCode).required(),
      city: Yup.string().matches(validCity).required(),
      shop: Yup.boolean().when(['workshop'], {
         is: false,
         then: Yup.boolean().oneOf([true], 'validation.atLeastOneField'),
      }),
      workshop: Yup.boolean().when(['shop'], {
         is: false,
         then: Yup.boolean().oneOf([true], 'validation.atLeastOneField'),
      }),
      workshopPositions: Yup.number().when('workshop', {
         is: true,
         then: Yup.number().min(1).required(),
      }),
      shopPositions: Yup.number().when('shop', {
         is: true,
         then: Yup.number().min(1).required(),
      }),
   },
   [
      ['shop', 'workshop'], // <--- adding your fields which need validation
   ]
)

export default schema
