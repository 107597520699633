import React from 'react'
import { Outlet } from 'react-router-dom'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import Footer from '../Footer'
import Header from '../Header'
import Sidebar from '../Sidebar'

function Main() {
   return (
      <>
         <main style={{ display: 'flex' }}>
            <Sidebar />
            <div style={{ width: '100%', minHeight: 'calc(100vh - 83px - 64px)' }}>
               <Header />

               <Outlet />
            </div>
         </main>
         <Footer />
      </>
   )
}

export default Main
