import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'

import { locales } from 'config/languages'
import { ReactComponent as EnIcon } from 'assets/svg/en-EN.svg'
import { ReactComponent as PlIcon } from 'assets/svg/pl-PL.svg'
import MenuPopover from 'shared/components/MenuPopover'

function getLanguageName(lang: string) {
   if (lang === 'en-US') {
      return 'English'
   }

   if (lang === 'pl-PL') {
      return 'Polski'
   }
   return lang
}

function getLanguageIcon(lang?: string) {
   if (lang === 'en-US') {
      return <EnIcon />
   }

   if (lang === 'pl-PL') {
      return <PlIcon />
   }
   return null
}

export default function LanguagePopover() {
   const anchorRef = useRef(null)
   const { i18n } = useTranslation()
   const [open, setOpen] = useState(false)

   function onChangeLang(lang: string) {
      i18n.changeLanguage(lang)
      setOpen(false)
   }

   function handleOpenMenu() {
      setOpen(true)
   }
   function handleCloseMenu() {
      setOpen(false)
   }

   return (
      <>
         <IconButton
            ref={anchorRef}
            onClick={handleOpenMenu}
            sx={{
               padding: 0,
               width: 44,
               height: 44,
               ...(open && { bgcolor: 'action.selected' }),
            }}
         >
            {getLanguageIcon(i18n.language)}
         </IconButton>

         <MenuPopover open={open} onClose={handleCloseMenu} anchorEl={anchorRef.current}>
            <Box sx={{ py: 1 }}>
               {locales?.map((lang) => (
                  <MenuItem
                     selected={lang.prefix === i18n.language}
                     key={lang.prefix}
                     onClick={() => onChangeLang(lang.prefix)}
                     sx={{ py: 1, px: 2.5 }}
                  >
                     <ListItemIcon>{getLanguageIcon(lang.prefix)}</ListItemIcon>

                     <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                        {getLanguageName(lang.name)}
                     </ListItemText>
                  </MenuItem>
               ))}
            </Box>
         </MenuPopover>
      </>
   )
}
