import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from '@mui/material/Container'
import InputAdornment from '@mui/material/InputAdornment'
import Grid from '@mui/material/Grid'

import InputField from 'shared/components/InputField'

export function FirstStep() {
   const { t } = useTranslation('TRANSLATION')
   return (
      <Container>
         <Grid sx={{ marginBottom: 4 }} container>
            <Grid item xs={12} md={12}>
               <InputField label={t('formFields.name')} name="firstName" />
            </Grid>
         </Grid>
         <Grid sx={{ marginBottom: 4 }} container>
            <Grid item xs={12} md={12}>
               <InputField label={t('formFields.surname')} name="lastName" />
            </Grid>
         </Grid>
         <Grid sx={{ marginBottom: 4 }} container>
            <Grid item xs={12} md={12}>
               <InputField label={t('formFields.email')} name="email" />
            </Grid>
         </Grid>
         <Grid sx={{ marginBottom: 4 }} container>
            <Grid item xs={12} md={12}>
               <InputField
                  InputProps={{
                     startAdornment: <InputAdornment position="start">+48</InputAdornment>,
                  }}
                  label={t('formFields.phoneNumber')}
                  name="telephoneNumber"
               />
            </Grid>
         </Grid>
      </Container>
   )
}
