import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'
import isEqual from 'lodash/isEqual'

import { fetchReflinkSettingsData, saveReflinkSettings } from './services'
import { ReflinkSettingsType, UpdateReflinkSettingsPayload, UpdateReflinkSettingsResult } from './types'

export function useReflinkSettingsQuery(): UseQueryResult<ReflinkSettingsType> {
   return useQuery('userReflinkSettings', fetchReflinkSettingsData, { staleTime: 3600000 })
}

export function useReflinkSettingsMutation() {
   const queryClient = useQueryClient()
   const initialData = queryClient.getQueryData<ReflinkSettingsType>('userReflinkSettings')

   return useMutation<UpdateReflinkSettingsResult, unknown, UpdateReflinkSettingsPayload, unknown>(
      (payload) => {
         if (isEqual(initialData, payload)) {
            throw new Error('nothing is changed')
         } else {
            return saveReflinkSettings(payload)
         }
      },
      {
         onSuccess: (data) => {
            if (data.status === 200) {
               queryClient.invalidateQueries('userReflinkSettings')
            }
         },
      }
   )
}
