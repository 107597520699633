import React from 'react'
import { styled } from '@mui/material/styles'

import { useProfileQuery } from 'shared/store/useProfile'
import { UserDataType } from 'shared/store/useProfile/types'

const RootStyle = styled('div')(({ theme }) => ({
   width: 154,
   height: 144,
   margin: 'auto',
   borderRadius: '50%',
   padding: theme.spacing(1),
   border: `1px dashed ${theme.palette.grey[500_32]}`,
}))

const DropZoneStyle = styled('div')({
   zIndex: 0,
   width: '100%',
   height: '100%',
   outline: 'none',
   display: 'flex',
   overflow: 'hidden',
   borderRadius: '50%',
   position: 'relative',
   alignItems: 'center',
   justifyContent: 'center',
   '& > *': { width: '100%', height: '100%' },
   '&:hover': {
      '& .placeholder': {
         zIndex: 9,
      },
   },
})

export default function UploadLogoUser() {
   const { data } = useProfileQuery<UserDataType>()

   return (
      <RootStyle>
         <DropZoneStyle>
            {data?.image ? <img src={`data:image/png;base64,${data?.image}`} alt="" /> : null}
         </DropZoneStyle>
      </RootStyle>
   )
}
