import React from 'react'
import { Outlet } from 'react-router-dom'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import Header from '../Header'
import Footer from '../Footer'

function UnauthorizedLayout() {
   return (
      <>
         <Header />

         <main style={{ minHeight: 'calc(100vh - 64px - 84.38px)', marginTop: '64px' }}>
            <Outlet />
         </main>
         <Footer />
      </>
   )
}

export default UnauthorizedLayout
