import { httpClient } from 'shared/lib'

import {
   AddLocationPayload,
   FetchLocationResult,
   Result,
   SaveAdminDetailsPayload,
   SaveLocationDetailsPayload,
} from './types'

export function fetchLocations(): Promise<FetchLocationResult> {
   return httpClient
      .get('location/')
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch((err) => {
         console.log(0, 'ERROR FROM fetchLocations()', err)
         throw new Error('apiErrors.apiProblem')
      })
}

export function saveLocationDetails(payload: SaveLocationDetailsPayload) {
   const url = `location/${payload.uuid}`

   return httpClient
      .put<Result>(url, payload)
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         console.log(0, 'ERROR FROM saveLocationDetails()', err)
         throw new Error('apiErrors.apiProblem')
      })
}

export function saveAdminDetails(payload: SaveAdminDetailsPayload) {
   const url = `user/location/admin/${payload.uuid}`

   return httpClient
      .put<Result>(url, payload)
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         console.log(0, 'ERROR FROM saveAdminDetailsI()', err)
         throw new Error('apiErrors.apiProblem')
      })
}

export function addLocation(payload: AddLocationPayload) {
   return httpClient
      .post<Result>('location/', payload)
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         console.log(0, 'ERROR FROM addLocation()', err)
         throw new Error('apiErrors.apiProblem')
      })
}
