import { httpClient } from 'shared/lib'
import { FetchUserProfileResult, UpdateProfilePayload, UpdateProfileResult, UpdateUserProfilePayload } from './types'

export function fetchProfileData() {
   return httpClient
      .get<FetchUserProfileResult>('user/currentUser')
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch((err) => {
         console.log(0, 'ERROR FROM fetchProfileData()', err)
         throw new Error('apiErrors.apiProblem')
      })
}

export function saveProfileData(payload: UpdateProfilePayload | UpdateUserProfilePayload) {
   const url = payload.type === 'admin' ? `user/${payload.uuid}` : `user/location/${payload.uuid}/`
   return httpClient
      .put<UpdateProfileResult>(url, payload)
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         console.log(0, 'ERROR FROM saveProfileData()', err)
         throw new Error('apiErrors.apiProblem')
      })
}
