import { Yup } from 'shared/lib'

const schema = Yup.object().shape({
   language: Yup.string().oneOf(['en-EN', 'pl-PL']),
   markup: Yup.string(),
   priceType: Yup.string().oneOf(['gross', 'net']),
   time: Yup.string(),
   displayMinPopup: Yup.boolean(),
})

export default schema
