import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { styled } from '@mui/material/styles'

import PageNotFoundIllustration from 'assets/ilustrations/illustration_404'

const RootStyle = styled('div')(() => ({
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
   minHeight: 'calc(100vh - 64px - 87px)',
}))

export default function Page404() {
   const { t } = useTranslation('TRANSLATION')
   const location = useLocation()
   return (
      <RootStyle>
         <Container>
            <Box sx={{ maxWidth: 'sm', margin: 'auto', textAlign: 'center' }}>
               <Typography variant="h3" paragraph>
                  {t('404.errorTitle')}
               </Typography>
               <Typography sx={{ color: 'text.secondary' }}>{t('404.errorText')}</Typography>
               <PageNotFoundIllustration sx={{ height: 260, my: { xs: 5, sm: 7 } }} />
               <Button
                  sx={{ color: 'white' }}
                  size="large"
                  variant="contained"
                  component={RouterLink}
                  to={location.pathname.includes('dashboard') ? '/dashboard' : '/'}
               >
                  {t('404.errorButton')}
               </Button>
            </Box>
         </Container>
      </RootStyle>
   )
}
