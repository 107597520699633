import { httpClient } from 'shared/lib'
import {
   EditDeliveryTimesPayload,
   FetchDeliveryTimesResult,
   Result,
   WholesalerDeliveryTimes,
   WholesalerType,
} from './types'

export function fetchWholesalers(locationId?: string) {
   return httpClient
      .get<WholesalerType[]>(`cooperation/getLocationWholesalers/${locationId}`)
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch((err) => {
         console.log(0, 'ERROR FROM fetchWholesalers()', err)
         throw new Error('apiErrors.apiProblem')
      })
}

export function fetchWholesalerDeliveryTimes(wholesalerId: string): Promise<WholesalerDeliveryTimes> {
   return httpClient
      .get<FetchDeliveryTimesResult>(`delivery/time/${wholesalerId}`)
      .then((res) => {
         if (res.data) {
            return {
               deliveryTimes: res.data.deliveryTimes,
               uuid: res.data.uuid,
            }
         }
         throw new Error('No data in request')
      })
      .catch((err) => {
         console.log(0, 'ERROR FROM fetchWholesalerDeliveryTimes()', err)
         throw new Error('apiErrors.apiProblem')
      })
}

export function editWholesalerDeliveryTimes(payload: EditDeliveryTimesPayload) {
   return httpClient
      .put<Result>(`delivery/time/${payload.uuid}`, {
         deliveryTimes: payload.deliveryTimes,
         wholesaleUuid: payload.wholesaleUuid,
      })
      .then((res) => {
         if (res.data) {
            return {
               status: 200,
            }
         }
         throw new Error('No data in request')
      })
      .catch((err) => {
         console.log(0, 'ERROR FROM editWholesalerDeliveryTimes()', err)
         throw new Error('apiErrors.apiProblem')
      })
}
