import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikProvider, useFormik } from 'formik'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'

import { useSettingsQuery, useSettingsMutation } from 'shared/store/useSettings'
import { UpdateSettingsPayloadType } from 'shared/store/useSettings/types'
import CustomSnackbar from 'shared/components/CustomSnackbar'
import SelectField from 'shared/components/SelectField'
import SwitchField from 'shared/components/SwitchField'
import InputField from 'shared/components/InputField'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'

import CompanyDataForm from './components/CompanyForm'
import { en, pl } from './locales'
import schema from './schema'

export default function Settings() {
   const { t, i18n } = useTranslation(['TRANSLATION', 'SETTINGS'])
   i18n.addResourceBundle('pl-PL', 'SETTINGS', pl)
   i18n.addResourceBundle('en-US', 'SETTINGS', en)

   const { data } = useSettingsQuery()
   const { mutate, isError: isMutationError, isLoading, isSuccess } = useSettingsMutation()
   const { user } = useLogin()

   const [displaySnackbar, setDisplaySnackbar] = useState(false)

   const languageOptions = [
      { name: 'English', value: 'en-EN' },
      { name: 'Polski', value: 'pl-PL' },
   ]

   const priceTypeOptions = [
      { name: t('SETTINGS:settingsSection.grossLabel'), value: 'gross' },
      { name: t('SETTINGS:settingsSection.netLabel'), value: 'net' },
   ]
   const formik = useFormik<SettingsType>({
      initialValues: {
         language: '',
         markup: '',
         priceType: '',
         time: '',
         displayMinPopup: false,
      },
      validationSchema: schema,
      onSubmit: handleSubmit,
   })

   const { values, setValues } = formik

   function handleSubmit() {
      const newSettings: UpdateSettingsPayloadType = {
         preferences: {
            language: values.language,
            markup: values.markup,
            priceType: values.priceType,
            time: values.time,
            displayMinPopup: values.displayMinPopup,
         },
      }
      mutate(newSettings, {
         onSettled: () => setDisplaySnackbar(true),
      })
   }

   useEffect(() => {
      if (data) {
         setValues({ ...data })
      }
   }, [data])

   return (
      <Card sx={{ p: 5, m: 2, mt: 3 }}>
         <Typography variant="h6" sx={{ fontWeight: 'normal', mb: 2, color: 'text.secondary' }}>
            {t('SETTINGS:settingsSection.label')}
         </Typography>
         {data ? (
            <div>
               <FormikProvider value={formik}>
                  <Form>
                     <Stack sx={{ ml: 2 }} direction="column" spacing={{ md: 5, xs: 3 }}>
                        <Stack direction={{ md: 'row', xs: 'column' }} spacing={3}>
                           <SelectField
                              name="language"
                              label={t('SETTINGS:settingsSection.languageLabel')}
                              options={languageOptions}
                           />
                           <InputField
                              name="markup"
                              fullWidth={false}
                              variant="standard"
                              inputProps={{
                                 type: 'number',
                                 min: '0',
                              }}
                              label={t('SETTINGS:settingsSection.markupLabel')}
                           />
                        </Stack>
                        <Stack direction={{ md: 'row', xs: 'column' }} spacing={3}>
                           <SelectField
                              name="priceType"
                              label={t('SETTINGS:settingsSection.typeOfPriceLabel')}
                              options={priceTypeOptions}
                           />
                           <Stack sx={{ mt: { md: 3, sx: 0 }, ml: 2 }} direction="row" spacing={{ md: 3, xs: 3 }}>
                              <InputField
                                 name="time"
                                 sx={{ width: '200px' }}
                                 variant="standard"
                                 label={t('SETTINGS:settingsSection.logisticMinimumLabel')}
                                 inputProps={{
                                    type: 'time',
                                 }}
                              />
                              <Tooltip
                                 placement="right-end"
                                 title={t('SETTINGS:settingsSection.logisticMinimumCheckboxTooltip') || ''}
                              >
                                 <div style={{ width: '10px', height: '10px', marginTop: 10 }}>
                                    <SwitchField name="displayMinPopup" label="" />
                                 </div>
                              </Tooltip>
                           </Stack>
                        </Stack>
                     </Stack>

                     <Box sx={{ mt: 6, display: 'flex', justifyContent: 'center' }}>
                        <Button disabled={isLoading} type="submit" sx={{ color: 'white' }} variant="contained">
                           {t('TRANSLATION:save')}
                        </Button>
                     </Box>
                  </Form>
               </FormikProvider>
               {user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) ? <CompanyDataForm /> : null}
            </div>
         ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
               <CircularProgress color="primary" />
            </div>
         )}
         <CustomSnackbar
            open={displaySnackbar}
            alertDetails={{
               alertType: isSuccess ? 'success' : 'error',
               alertMessage: isMutationError ? t('TRANSLATION:failedSave') : t('TRANSLATION:successSave'),
            }}
            handleClose={() => setDisplaySnackbar(false)}
         />
      </Card>
   )
}
