import AutoEuro from 'assets/images/wholesalers/Auto Euro.png'
import AutoFrance from 'assets/images/wholesalers/Auto France.png'
import AutoGH from 'assets/images/wholesalers/Auto GH.png'
import AutoLand from 'assets/images/wholesalers/Auto land.png'
import AutoPrima from 'assets/images/wholesalers/Auto Prima.png'
import AutoZatoka from 'assets/images/wholesalers/Auto Zatoka.png'
import AutoPartnerHolding from 'assets/images/wholesalers/Auto-Partner Holding.png'
import AutoPartnerSA from 'assets/images/wholesalers/Autopartner SA.png'
import BHMD from 'assets/images/wholesalers/BHMD.png'
import Elit from 'assets/images/wholesalers/Elit.png'
import Gordon from 'assets/images/wholesalers/Gordon.png'
import Hart from 'assets/images/wholesalers/Hart.png'
import Hipol from 'assets/images/wholesalers/Hipol.png'
import InterCarsIBM from 'assets/images/wholesalers/Inter Cars IBM.png'
import InterParts from 'assets/images/wholesalers/Inter Parts.png'
import InterTeam from 'assets/images/wholesalers/Inter Team.png'
import Intervito from 'assets/images/wholesalers/Intervito.png'
import JaroFiltr from 'assets/images/wholesalers/Jaro-Filtr.png'
import MasterSport from 'assets/images/wholesalers/Master Sport.png'
import MotoProfil from 'assets/images/wholesalers/Moto-Profil.png'
import Motogama from 'assets/images/wholesalers/Motogama.png'
import Motoleg from 'assets/images/wholesalers/Motoleg.png'
import Motorol from 'assets/images/wholesalers/Motorol.png'
import MPAuto from 'assets/images/wholesalers/MP Auto.png'
import Rodon from 'assets/images/wholesalers/Rodon.png'
import Stachmot from 'assets/images/wholesalers/Stachmot.png'
import Syndicar from 'assets/images/wholesalers/Syndicar.png'
import Vanking from 'assets/images/wholesalers/Vanking Celkar Group.png'

export function wholesalersLogosMap(wholesalerName: string) {
   switch (wholesalerName) {
      case 'Auto Euro':
         return AutoEuro
      case 'Auto France':
         return AutoFrance
      case 'Auto GH':
         return AutoGH
      case 'Auto land':
         return AutoLand
      case 'Auto Prima':
         return AutoPrima
      case 'Auto Zatoka':
         return AutoZatoka
      case 'Auto-Partner Holding':
         return AutoPartnerHolding
      case 'Autopartner SA':
         return AutoPartnerSA
      case 'BHMD':
         return BHMD
      case 'Elit':
         return Elit
      case 'Gordon':
         return Gordon
      case 'Hart':
         return Hart
      case 'Hipol':
         return Hipol
      case 'Inter Cars IBM':
         return InterCarsIBM
      case 'Inter Parts':
         return InterParts
      case 'Inter Team':
         return InterTeam
      case 'Intervito':
         return Intervito
      case 'Jaro-Filtr':
         return JaroFiltr
      case 'Master Sport':
         return MasterSport
      case 'Moto-Profil':
         return MotoProfil
      case 'Motogama':
         return Motogama
      case 'Motoleg':
         return Motoleg
      case 'Motorol':
         return Motorol
      case 'MP Auto':
         return MPAuto
      case 'Rodon':
         return Rodon
      case 'Stachmot':
         return Stachmot
      case 'Syndicar':
         return Syndicar
      case 'Vanking Celkar Group':
         return Vanking
      default:
         return ''
   }
}
