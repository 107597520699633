import { Yup } from 'shared/lib'

const validTaxID = /^\d{10}$/
const validCompanyName = /^([a-zA-Z0-9żźćńółęąśŻŹŚŁĆŃÓĘĄ'",-][.]?[\s]?)*([a-zA-Z0-9żźćńółęąśŻŹŚŁĆŃÓĘĄ]\.?)$/
const validTelephoneNumber = /^[0-9]{9}$/

const schema = Yup.object().shape({
   nip: Yup.string().matches(validTaxID),
   phoneNumber: Yup.string().matches(validTelephoneNumber, 'validation.telephoneNumberPattern'),
   companyName: Yup.string().matches(validCompanyName),
   defaultCartDescription: Yup.string(),
   address: Yup.string(),
})

export default schema
