import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import FacebookIcon from '@mui/icons-material/Facebook'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'

import logoFunds from 'assets/images/logo-ue.png'
import logoEU from 'assets/images/logo-funds.png'
import logo from 'assets/images/logo.png'
import { RootStyle, LinksContainer, LogoUE, LogoFunds } from './styles'

export default function Footer() {
   const { t } = useTranslation('UNAUTHORIZED')
   const theme = useTheme()
   const LINKS = [
      { name: t('footer.termsOfUseLabel'), href: '/terms-of-use' },
      { name: t('footer.privacyPolicyLabel'), href: '/privacy-policy' },
      { name: 'Tel. 500 195 799', href: 'tel:500195799' },
      { name: 'Email: kontakt@motorro.pl', href: 'mailto:kontakt@motorro.pl' },
   ]

   return (
      <footer>
         <RootStyle>
            <Divider />

            <Container maxWidth="xl" sx={{ pt: 2 }}>
               <Grid
                  spacing={1}
                  container
                  justifyContent={{ xs: 'center', md: 'space-between' }}
                  sx={{ textAlign: { xs: 'center', md: 'left' } }}
               >
                  <Grid item xs={12} md={2}>
                     <img height="40px" src={logo} alt="logo" />
                  </Grid>

                  <Grid item xs={12} md={7}>
                     <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
                        {LINKS.map((link) => (
                           <LinksContainer key={link.name}>
                              <RouterLink
                                 style={{
                                    marginLeft: '40px',
                                    textDecoration: 'none',
                                    color: theme.palette.primary.dark,
                                 }}
                                 to={link.href}
                              >
                                 {link.name}
                              </RouterLink>
                           </LinksContainer>
                        ))}
                     </Stack>
                  </Grid>

                  <Grid item xs={12} md={1}>
                     <IconButton
                        sx={{ marginTop: '-12px' }}
                        target="_blank"
                        href="https://www.facebook.com/motorromarketplace/"
                     >
                        <FacebookIcon color="secondary" sx={{ fontSize: 29 }} />
                     </IconButton>

                     <IconButton
                        sx={{ marginTop: '-12px', marginLeft: '-15px' }}
                        target="_blank"
                        href="https://pl.linkedin.com/company/motorro"
                     >
                        <LinkedInIcon color="secondary" sx={{ fontSize: 29 }} />
                     </IconButton>
                  </Grid>

                  <Grid item xs={12} md={2}>
                     <LogoUE src={logoFunds} alt="logoFunds" />

                     <LogoFunds src={logoEU} alt="logoUE" />
                  </Grid>
               </Grid>

               <Typography
                  component="p"
                  variant="body2"
                  sx={{
                     fontSize: 13,
                     textAlign: { xs: 'center', md: 'center' },
                  }}
               >
                  {t('footer.rights')}
               </Typography>
            </Container>
         </RootStyle>
      </footer>
   )
}
