import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

interface Props {
   open: boolean
   alertDetails: AlertType
   handleClose: () => void
}

export default function CustomSnackbar({ open, alertDetails, handleClose }: Props) {
   return (
      <Snackbar
         sx={{ marginTop: 5 }}
         anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
         }}
         open={open}
         autoHideDuration={6000}
         onClose={handleClose}
      >
         <Alert onClose={handleClose} severity={alertDetails.alertType} sx={{ width: '100%' }}>
            {alertDetails.alertMessage}
         </Alert>
      </Snackbar>
   )
}
