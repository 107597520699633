import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'
import { fetchWholesalers, fetchWholesalerDeliveryTimes, editWholesalerDeliveryTimes } from './services'
import { EditDeliveryTimesPayload, Result, StateType } from './types'

export default function useWholesalers(locationId?: string): UseQueryResult<StateType> {
   return useQuery(['wholesalers', 'list', locationId], () => fetchWholesalers(locationId), {
      initialData: [],
      enabled: !!locationId,
   })
}

export function useDeliveryTimesQuery(wholesalerId: string) {
   return useQuery(['wholesalers', 'times', wholesalerId], () => fetchWholesalerDeliveryTimes(wholesalerId))
}

export function useDeliveryTimesMutation() {
   const queryClient = useQueryClient()

   return useMutation<
      Result & Pick<EditDeliveryTimesPayload, 'wholesaleUuid'>,
      unknown,
      EditDeliveryTimesPayload,
      unknown
   >(
      async (payload) => {
         const { status } = await editWholesalerDeliveryTimes(payload)
         return {
            status,
            wholesaleUuid: payload.wholesaleUuid,
         }
      },
      {
         onSuccess: (data) => {
            if (data.status === 200) {
               queryClient.invalidateQueries(['wholesalers', 'times', data.wholesaleUuid])
            }
         },
      }
   )
}
