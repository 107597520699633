import React, { useEffect, useState } from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { useReflinkSettingsMutation, useReflinkSettingsQuery } from 'shared/store/useReflinkSettings'
import InputField from 'shared/components/InputField'
import { UpdateReflinkSettingsPayload } from 'shared/store/useReflinkSettings/types'
import CustomSnackbar from 'shared/components/CustomSnackbar'

import schema from './schema'

export default function CompanyDataForm() {
   const { t } = useTranslation(['TRANSLATION', 'SETTINGS'])
   const [displaySnackbar, setDisplaySnackbar] = useState(false)

   const { data } = useReflinkSettingsQuery()
   const { mutate, isError: isMutationError, isLoading, isSuccess } = useReflinkSettingsMutation()

   const formik = useFormik<CompanyDataSettingsInitialValues>({
      initialValues: {
         companyName: '',
         address: '',
         defaultCartDescription: '',
         phoneNumber: '',
         nip: '',
      },
      validationSchema: schema,
      onSubmit: handleSubmit,
   })

   const { values, setValues } = formik

   function handleSubmit() {
      const newSettings: UpdateReflinkSettingsPayload = {
         address: values.address,
         companyName: values.companyName,
         defaultCartDescription: values.defaultCartDescription,
         nip: values.nip,
         phoneNumber: values.phoneNumber,
      }
      mutate(newSettings, {
         onSettled: () => setDisplaySnackbar(true),
      })
   }

   useEffect(() => {
      if (data) {
         setValues({ ...data })
      }
   }, [data])

   return (
      <div>
         {data ? (
            <div>
               <FormikProvider value={formik}>
                  <Form>
                     <Typography variant="h6" sx={{ mt: 4, fontWeight: 'normal', mb: 2, color: 'text.secondary' }}>
                        {t('SETTINGS:companyData.label')}
                     </Typography>
                     <Stack direction={{ md: 'row', xs: 'column' }} spacing={4}>
                        <Stack>
                           <Stack
                              sx={{ float: 'left', ml: 2 }}
                              direction={{ md: 'row', xs: 'column' }}
                              spacing={{ md: 5, xs: 3 }}
                           >
                              <InputField
                                 fullWidth={false}
                                 name="companyName"
                                 variant="standard"
                                 label={t('TRANSLATION:formFields.companyName')}
                              />
                              <InputField
                                 fullWidth={false}
                                 name="nip"
                                 variant="standard"
                                 label={t('TRANSLATION:formFields.taxID')}
                              />
                           </Stack>
                           <Stack
                              sx={{ ml: 2, mt: 3, float: 'left' }}
                              direction={{ md: 'row', xs: 'column' }}
                              spacing={{ md: 5, xs: 3 }}
                           >
                              <InputField
                                 fullWidth={false}
                                 name="address"
                                 variant="standard"
                                 label={t('SETTINGS:companyData.companyAddressLabel')}
                              />
                              <InputField
                                 fullWidth={false}
                                 name="phoneNumber"
                                 variant="standard"
                                 label={t('TRANSLATION:formFields.phoneNumber')}
                              />
                           </Stack>
                        </Stack>
                        <Stack spacing={{ md: 5, xs: 0 }}>
                           <Tooltip title={t('SETTINGS:companyData.defaultCartDescTooltip') || ''}>
                              <div>
                                 <InputField
                                    fullWidth
                                    name="defaultCartDescription"
                                    sx={{
                                       float: 'right',
                                       minWidth: { md: 400, xs: 200 },
                                       top: { md: 15, xs: 0 },
                                       left: { md: 30 },
                                    }}
                                    label={t('SETTINGS:companyData.defaultCartDesc')}
                                    margin="normal"
                                    multiline
                                    rows={3}
                                    InputLabelProps={{ shrink: true }}
                                 />
                              </div>
                           </Tooltip>
                        </Stack>
                     </Stack>
                     <Box sx={{ mt: 6, display: 'flex', justifyContent: 'center' }}>
                        <Button disabled={isLoading} type="submit" sx={{ color: 'white' }} variant="contained">
                           {t('TRANSLATION:save')}
                        </Button>
                     </Box>
                  </Form>
               </FormikProvider>
            </div>
         ) : null}
         <CustomSnackbar
            open={displaySnackbar}
            alertDetails={{
               alertType: isSuccess ? 'success' : 'error',
               alertMessage: isMutationError ? t('TRANSLATION:failedSave') : t('TRANSLATION:successSave'),
            }}
            handleClose={() => setDisplaySnackbar(false)}
         />
      </div>
   )
}
