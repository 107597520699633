import isEqual from 'lodash/isEqual'
import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'
import { addLocation, fetchLocations, saveAdminDetails, saveLocationDetails } from './services'

import { AddLocationPayload, Result, SaveAdminDetailsPayload, SaveLocationDetailsPayload, StateType } from './types'

async function getLocations() {
   const request = await fetchLocations().then((response) => response.map((location) => ({
            uuid: location.uuid,
            locationName: location.name,
            street: location.street,
            postCode: location.zipCode,
            city: location.city,
            houseNumber: location.houseNumber,
            // temp
            shippingStreet: location.street,
            shippingPostCode: location.zipCode,
            shippingCity: location.city,
            shippingHouseNumber: location.houseNumber,
            // end of temp
            country: location.country,
            adminFirstName: location.admin.name,
            adminLastName: location.admin.surname,
            adminID: location.adminUuid,
            adminEmail: location.admin.email,
            adminTelephoneNumber: location.admin.phoneNumber,
         })))

   return request
}

export function useLocationsQuery(): UseQueryResult<StateType> {
   return useQuery('locations', getLocations, { staleTime: 3600000 })
}

export function useDataLocationsMutation() {
   const queryClient = useQueryClient()

   return useMutation<Result, unknown, SaveLocationDetailsPayload, unknown>(
      (payload) => {
         const initialData = queryClient
            .getQueryData<StateType>('locations')
            ?.find((location) => location.uuid === payload.uuid)
         const initialPayload = initialData
            ? {
                 city: initialData.city,
                 country: initialData.country,
                 houseNumber: initialData.houseNumber,
                 locationName: initialData.locationName,
                 street: initialData.street,
                 uuid: initialData.uuid,
                 zipCode: initialData.postCode,
              }
            : {}
         if (isEqual(initialPayload, payload)) {
            throw new Error('Nothing is changed')
         } else {
            return saveLocationDetails(payload)
         }
      },
      {
         onSuccess: (data) => {
            if (data.status === 200) {
               queryClient.invalidateQueries('locations')
            }
         },
      }
   )
}

export function useAdminDetailsLocationMutation() {
   const queryClient = useQueryClient()
   return useMutation<Result, unknown, SaveAdminDetailsPayload, unknown>(
      (payload) => {
         const initialData = queryClient
            .getQueryData<StateType>('locations')
            ?.find((location) => location.uuid === payload.locationUuid)

         const initialPayload = initialData
            ? {
                 uuid: initialData.adminID,
                 name: initialData.adminFirstName,
                 surname: initialData.adminLastName,
                 phoneNumber: initialData.adminTelephoneNumber,
                 email: initialData.adminEmail,
                 locationUuid: initialData.uuid,
                 country: initialData.country,
              }
            : {}

         if (isEqual(initialPayload, payload)) {
            throw new Error('nothing is changed')
         } else {
            return saveAdminDetails(payload)
         }
      },
      {
         onSuccess: (data) => {
            if (data.status === 200) {
               queryClient.invalidateQueries('locations')
            }
         },
      }
   )
}

export function useAddLocationMutation() {
   const queryClient = useQueryClient()

   return useMutation<Result, unknown, AddLocationPayload, unknown>(
      (payload) => addLocation(payload),
      {
         onSuccess: (data) => {
            if (data.status === 200) {
               queryClient.invalidateQueries('locations')
            }
         },
      }
   )
}
