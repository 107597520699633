import { useMutation, useQueryClient } from 'react-query'
import JwtDecode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import { LocalStorageKeys, UserRoles } from 'shared/consts'
import { authorizeUser } from './services'
import { AuthorizationResult, JwtTokenData, LoginPayload, StateType } from './types'

const INITIAL_STATE: StateType = { user: undefined, uuid: '', isLogged: false, roles: [], freeSubscriptions: 0 }

async function loginIn(payload: LoginPayload): Promise<StateType> {
   const userData = await authorizeUser(payload)

   if (
      userData.fingerprintVerificationStatus === 'INVALID_VERIFICATION_CODE' ||
      userData.fingerprintVerificationStatus === 'VERIFICATION_CODE_SENT' ||
      userData.fingerprintVerificationStatus === 'SMS_LIMIT_REACHED' ||
      userData.fingerprintVerificationStatus === 'INVALID_CREDENTIALS' ||
      userData.fingerprintVerificationStatus === 'Unauthorized'
   ) {
      throw new Error(userData.fingerprintVerificationStatus)
   }

   if (!userData.accessToken) {
      return INITIAL_STATE
   }

   const token = userData.accessToken
   const user = JwtDecode<JwtTokenData>(token)
   const expirationDate = new Date(new Date().getTime() + user.expires * 1000)
   const roles = user.authorities.split(',') as UserRoles[]
   const { sub } = user

   localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, token)
   localStorage.setItem(LocalStorageKeys.EXPIRES_IN, `${expirationDate}`)

   return {
      user: {
         name: 'Gracjan',
      },
      roles,
      uuid: sub,
      isLogged: true,
      freeSubscriptions: user.freeSubscriptions,
   }
}

async function loginOut() {
   localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN)
   localStorage.removeItem(LocalStorageKeys.EXPIRES_IN)
}

function getInitialState() {
   const token = localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN)
   if (token) {
      const user = JwtDecode<JwtTokenData>(token)
      const roles = user.authorities.split(',') as UserRoles[]
      return {
         user: {
            name: 'Gracjan',
         },
         uuid: user.sub,
         roles,
         isLogged: true,
         freeSubscriptions: user.freeSubscriptions,
      }
   }
   return INITIAL_STATE
}

export default function useLogin() {
   const queryClient = useQueryClient()
   const navigate = useNavigate()

   const user = queryClient.getQueryData<StateType>('user') || getInitialState()

   const {
      mutate: login,
      error,
      isError,
      isLoading,
   } = useMutation<
      StateType,
      { message: PropType<AuthorizationResult, 'fingerprintVerificationStatus'> },
      LoginPayload
   >((loginData) => loginIn(loginData), {
      onSuccess: (data) => {
         queryClient.setQueryData<StateType>('user', {
            ...user,
            ...data,
         })
         queryClient.invalidateQueries('user')
      },
      // onError: (err) => {
      //    queryClient.setQueryData<StateType>('user', {
      //       ...user,
      //    })
      // },
   })

   const { mutate: logout } = useMutation(loginOut, {
      onSuccess: () => {
         queryClient.removeQueries()
         // queryClient.setQueryData<StateType>('user', {
         //    ...INITIAL_STATE,
         // })
         navigate('/')
      },
   })

   return { user, login, logout, error, isError, isLoading }
}
