import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import NotFound from 'shared/components/404'
import routes from 'config/routes'
import PrivateRoute from 'shared/components/PrivateRoute'
import { en, pl } from './locales'
import Layout from './components/Layout'
import Homepage from './views/Homepage'

export default function Authorized() {
   const { i18n } = useTranslation()

   i18n.addResourceBundle('pl-PL', 'AUTHORIZED', pl)
   i18n.addResourceBundle('en-US', 'AUTHORIZED', en)

   return (
      <Routes>
         <Route path="/" element={<Layout />}>
            <Route index element={<Homepage />} />
            {routes.map((route) => (
               <Route
                  key={route.name}
                  path={route.path}
                  element={<PrivateRoute component={route.element} roles={route.authority} />}
               />
            ))}
            <Route path="*" element={<NotFound />} />
         </Route>
      </Routes>
   )
}
