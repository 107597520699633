import { styled } from '@mui/material/styles'
import Link from '@mui/material/Link'
import { Container } from '@mui/material'

export const StyledLink = styled(Link)(({ theme }) => ({
   textDecoration: 'none',
   color: theme.palette.primary.dark,
}))

export const StyledLine = styled('hr')(() => ({
   width: '100%',
   opacity: 0.3,
}))

export const RootStyle = styled('div')(() => ({
   textAlign: 'center',
   justifyContent: 'center',
   marginTop: '40px',
   marginBottom: '30px',
   minHeight: 'calc(100vh - 64px - 87px)',
}))

export const StyledContainer = styled(Container)(() => ({
   textAlign: 'left',
   marginTop: '10px',
}))

export const StyledLi = styled('li')(() => ({
   marginTop: '8px',
}))
