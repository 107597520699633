import React from 'react'
import { Navigate } from 'react-router-dom'

import { UserRoles } from 'shared/consts'
import useLogin from 'shared/store/useLogin'

import AccessDenied from '../AccessDenied'

interface Props {
   component: React.ComponentType
   roles: UserRoles[]
}

function PrivateRoute({ component: RouteComponent, roles }: Props) {
   const { user } = useLogin()
   const isAuthenticated = user.isLogged
   const userHasRequiredRole = user.roles.length && roles.some((role) => user.roles.indexOf(role) >= 0)

   if (isAuthenticated && userHasRequiredRole) {
      return <RouteComponent />
   }

   if (isAuthenticated && !userHasRequiredRole) {
      return <AccessDenied />
   }

   return <Navigate to="/" />
}

export default PrivateRoute
