import React, { useEffect, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import MailIcon from '@mui/icons-material/Mail'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'

import { WholesalerType } from 'shared/store/useWholesalers/types'
import { wholesalersLogosMap } from 'shared/utils/helpers/wholesalerLogosMap'
import { StyledCard, StatusCorner, DeleteCorner, EditTimesCorner, EditCorner, StyledImg } from './styles'
import { WholesalerActionType } from '../../types'

type Props = {
   activeWholesaler: WholesalerType
   handleWholesaler: (wholesalerId: string, type: WholesalerActionType) => void
}

export default function WholesalerCard({ activeWholesaler, handleWholesaler }: Props) {
   const [selectedDepsCount, setSelectedDepsCount] = useState(0)

   useEffect(() => {
      if (activeWholesaler) {
         const selectedDeps = activeWholesaler.departments.filter((item) => !!item.type)
         setSelectedDepsCount(selectedDeps.length)
      }
   }, [activeWholesaler])

   return (
      <Grid item xs={6} md={4} lg={3}>
         <StyledCard>
            <StatusCorner pendingcoop={`${activeWholesaler.askCooperation}`}>
               {activeWholesaler.askCooperation ? (
                  <QueryBuilderIcon color="primary" sx={{ mt: '3px', ml: '4px' }} />
               ) : (
                  <CheckIcon sx={{ color: 'green', mt: '3px', ml: '4px' }} />
               )}
            </StatusCorner>
            <DeleteCorner onClick={() => handleWholesaler(activeWholesaler.wholesaleUuid, 'deleteWholesaler')}>
               <DeleteIcon />
            </DeleteCorner>
            <EditTimesCorner
               onClick={
                  activeWholesaler.askCooperation
                     ? () => {
                          /* tu obsługa maila */
                       }
                     : () => handleWholesaler(activeWholesaler.wholesaleUuid, 'editTimes')
               }
            >
               {activeWholesaler.askCooperation ? <MailIcon /> : <AccessTimeIcon />}
            </EditTimesCorner>
            <EditCorner onClick={() => handleWholesaler(activeWholesaler.wholesaleUuid, 'editWholesaler')}>
               <EditIcon />
            </EditCorner>
            <Tooltip
               title={`${activeWholesaler.wholesaleName} ${selectedDepsCount}/3 min: ${
                  activeWholesaler.minLogistics === null ? '-' : activeWholesaler.minLogistics
               }`}
            >
               <StyledImg
                  src={wholesalersLogosMap(activeWholesaler.wholesaleName)}
                  alt={activeWholesaler.wholesaleName}
               />
            </Tooltip>
         </StyledCard>
      </Grid>
   )
}
