import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik, FormikProvider, Form } from 'formik'
import { Buffer } from 'buffer'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'

import { WholesalerType } from 'shared/store/useWholesalers/types'
import InputField from 'shared/components/InputField'
import SelectField from 'shared/components/SelectField'
import RadioField from 'shared/components/RadioButton'

import schema from './schema'
import { WholesalersModalInitialValues } from '../../types'

type Props = {
   wholesalerInfo?: WholesalerType
}

type Option = {
   name: string
   value: string
}

export default function WholesalerInfoModal({ wholesalerInfo }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'WHOLESALERS'])
   const [departments, setDepartments] = useState<Option[]>([])

   const paymentOptions = [
      { value: 'fv', label: 'FV' },
      { value: 'wz', label: 'WZ' },
   ]

   const formik = useFormik<WholesalersModalInitialValues>({
      initialValues: {
         loginParameter: '',
         passwordParameter: '',
         additionalParameter: '',
         firstDepartment: '',
         secondDepartment: '',
         thirdDepartment: '',
         deliveryDepartment: '',
         logisticMinimums: 0,
         fv: false,
         wz: false,
      },
      validationSchema: schema,
      onSubmit: () => console.log('submitting'),
   })

   const { setValues } = formik

   useEffect(() => {
      if (wholesalerInfo) {
         const options: Option[] = []
         wholesalerInfo?.departments.map((option) => options.push({ value: option.departmentUuid, name: option.name }))
         options.concat([{ value: '', name: '' }])
         setDepartments(options)
         if (!wholesalerInfo.askCooperation && wholesalerInfo.cooperation) {
            setValues({
               loginParameter: Buffer.from(wholesalerInfo.login, 'base64').toString('ascii'),
               passwordParameter: Buffer.from(wholesalerInfo.password, 'base64').toString('ascii'),
               additionalParameter: wholesalerInfo.additionalParameter
                  ? Buffer.from(wholesalerInfo.additionalParameter, 'base64').toString('ascii')
                  : '',
               firstDepartment: wholesalerInfo.departments.find((item) => item.type === 'FIRST')?.departmentUuid,
               secondDepartment: wholesalerInfo.departments.find((item) => item.type === 'SECOND')?.departmentUuid,
               thirdDepartment: wholesalerInfo.departments.find((item) => item.type === 'THIRD')?.departmentUuid,
               deliveryDepartment: wholesalerInfo.departments.find(
                  (item) => item.departmentUuid === wholesalerInfo.deliveryDepartment
               )?.departmentUuid,
               logisticMinimums: wholesalerInfo.minLogistics ? wholesalerInfo.minLogistics : 0,

               fv: false,
               wz: false,
            })
         }
      }
   }, [wholesalerInfo])
   return (
      <div>
         <FormikProvider value={formik}>
            <Form>
               <Typography variant="body1" sx={{ mt: 1, mb: 1, color: 'text.secondary' }}>
                  {!wholesalerInfo?.cooperation
                     ? t('WHOLESALERS:addCooperationDialog')
                     : t('WHOLESALERS:editWholesalerDialog')}
               </Typography>
               <Stack direction="column" spacing={{ xs: 3, md: 4 }}>
                  <Stack sx={{ pl: 2 }} direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 3 }}>
                     <Tooltip title={wholesalerInfo?.loginTooltip || ''}>
                        <div style={{ width: '100%' }}>
                           <InputField
                              required
                              name="loginParameter"
                              variant="standard"
                              label={wholesalerInfo?.loginParameterName}
                           />
                        </div>
                     </Tooltip>
                     <Tooltip title={wholesalerInfo?.passwordTooltip || ''}>
                        <div style={{ width: '100%' }}>
                           <InputField
                              required
                              name="passwordParameter"
                              variant="standard"
                              type="password"
                              label={wholesalerInfo?.passwordParameterName}
                           />
                        </div>
                     </Tooltip>
                     <Tooltip title={wholesalerInfo?.additionalParameterTooltip || ''}>
                        <div style={{ width: '100%' }}>
                           <InputField
                              required
                              name="additionalParameter"
                              variant="standard"
                              type="password"
                              label={
                                 wholesalerInfo?.additionalParameterName
                                    ? wholesalerInfo?.additionalParameterName
                                    : t('WHOLESALERS:additional')
                              }
                              disabled={wholesalerInfo?.additionalParameter === null}
                           />
                        </div>
                     </Tooltip>
                  </Stack>
                  <Stack sx={{ pl: 2 }} direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 3 }}>
                     <SelectField
                        required
                        name="firstDepartment"
                        label={t('WHOLESALERS:firstDep')}
                        options={departments?.filter(
                           (item) =>
                              item.value !== formik.values.secondDepartment &&
                              item.value !== formik.values.thirdDepartment
                        )}
                        maxRows={5}
                     />

                     <SelectField
                        required
                        name="secondDepartment"
                        label={t('WHOLESALERS:secondDep')}
                        options={departments?.filter(
                           (item) =>
                              item.value !== formik.values.firstDepartment &&
                              item.value !== formik.values.thirdDepartment
                        )}
                        maxRows={5}
                     />
                     <SelectField
                        required
                        name="thirdDepartment"
                        label={t('WHOLESALERS:thirdDep')}
                        options={departments?.filter(
                           (item) =>
                              item.value !== formik.values.firstDepartment &&
                              item.value !== formik.values.secondDepartment
                        )}
                        maxRows={5}
                     />
                  </Stack>
                  <Stack sx={{ pl: 2 }} direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 3 }}>
                     <SelectField
                        required
                        name="deliveryDepartment"
                        label={t('WHOLESALERS:deliveryDep')}
                        options={departments?.filter(
                           (item) =>
                              item.value === formik.values.firstDepartment ||
                              item.value === formik.values.secondDepartment ||
                              item.value === formik.values.thirdDepartment
                        )}
                        maxRows={5}
                     />
                     <InputField
                        fullWidth={false}
                        required
                        variant="standard"
                        name="logisticMinimums"
                        type="number"
                        label={t('TRANSLATION:formFields.logisticMinimums')}
                        inputProps={{
                           min: 0,
                        }}
                     />
                     {(wholesalerInfo?.haveFvDocument && wholesalerInfo?.haveWzDocument) ||
                     !wholesalerInfo?.haveWzDocument ? (
                        <div style={{ marginTop: 7, display: 'flex' }}>
                           <RadioField options={paymentOptions} name="paymentMethods" />
                        </div>
                     ) : null}
                  </Stack>
               </Stack>
            </Form>
         </FormikProvider>
      </div>
   )
}
