import React from 'react'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import TextField, { StandardTextFieldProps } from '@mui/material/TextField'
import { AdminDataType } from 'shared/store/useProfile/types'
import { LocationType } from 'shared/store/useLocations/types'

interface Props extends StandardTextFieldProps {
   name:
      | keyof AdminDataType
      | keyof RegisterInitialValues
      | keyof NewLocationInitialValues
      | keyof LocationType
      | keyof CompanyDataSettingsInitialValues
      | keyof SettingsType
      | string
}

function InputField({ name, ...rest }: Props) {
   const [field, meta] = useField(name)

   const { t } = useTranslation('TRANSLATION')
   return (
      <TextField
         autoComplete="new-password"
         fullWidth
         margin="none"
         error={Boolean(meta.error)}
         helperText={meta.error && t(meta.error)}
         {...field}
         {...rest}
      />
   )
}

export default InputField
