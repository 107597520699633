import React, { useState } from 'react'
import { styled, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'

const StyledImg = styled('img')(({ theme }) => ({
   height: '35px',
   [theme.breakpoints.down('md')]: {
      display: 'none',
   },
   marginTop: 25,
}))

const StyledDialogButton = styled(Button)(() => ({
   textTransform: 'uppercase',
   fontWeight: 'normal',
}))

const StyledBox = styled(Card)(() => ({
   padding: 20,
   width: '100%',
   marginTop: 10,
}))

const StyledTypography = styled(Typography)(() => ({
   textTransform: 'uppercase',
   color: 'GrayText',
}))

const StyledDeleteIcon = styled(DeleteIcon)(() => ({
   fontSize: 30,
   '&:hover': {
      color: 'red',
      cursor: 'pointer',
   },
}))

interface Props {
   index: string
   name: string
   producer: string
   date: string
}

export default function WatchedProduct({ index, name, producer, date }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'WATCHLIST'])
   const theme = useTheme()

   const [open, setOpen] = useState(false)
   return (
      <StyledBox>
         <Stack sx={{ paddingLeft: 3 }} spacing={5} direction={{ xs: 'column', md: 'row' }}>
            {/* tutaj bedzie link + koncowka to będzie nazwa producenta.png */}
            <StyledImg src="https://cdn.intercars.eu/files/1/0/5/105.png?v=2014-10-13" alt="wholesaleLogo" />
            <Stack>
               <StyledTypography variant="h6">{name}</StyledTypography>
               <Stack spacing={1} direction={{ xs: 'column', md: 'row' }}>
                  <Typography variant="subtitle2">{t('WATCHLIST:watchedProduct.indexLabel')}</Typography>
                  <StyledTypography variant="body2">{index}</StyledTypography>
               </Stack>
               <Stack spacing={1} direction={{ xs: 'column', md: 'row' }}>
                  <Typography variant="subtitle2">{t('WATCHLIST:watchedProduct.producerLabel')}</Typography>
                  <StyledTypography variant="body2">{producer}</StyledTypography>
               </Stack>
               <Stack spacing={1} direction={{ xs: 'column', md: 'row' }}>
                  <Typography variant="subtitle2">{t('WATCHLIST:watchedProduct.dateLabel')}</Typography>
                  <StyledTypography variant="body2">{date}</StyledTypography>
               </Stack>
            </Stack>
         </Stack>
         <IconButton onClick={() => setOpen(true)} sx={{ top: 5, right: 5, position: 'absolute' }}>
            <StyledDeleteIcon />
         </IconButton>
         <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>
               <Typography variant="h4" sx={{ color: theme.palette.primary.main, fontWeight: 'normal' }}>
                  {t('WATCHLIST:watchedProduct.deleteModalTitle')}
               </Typography>
            </DialogTitle>
            <DialogContent> {t('WATCHLIST:watchedProduct.deleteModalContent')} </DialogContent>
            <DialogActions sx={{ mb: 1 }}>
               <StyledDialogButton onClick={() => setOpen(false)}>{t('TRANSLATION:close')}</StyledDialogButton>
               <StyledDialogButton> {t('TRANSLATION:delete')}</StyledDialogButton>
            </DialogActions>
         </Dialog>
      </StyledBox>
   )
}
