import React from 'react'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import { useTranslation } from 'react-i18next'

import { WholesalerType } from 'shared/store/useWholesalers/types'

import { wholesalersLogosMap } from 'shared/utils/helpers/wholesalerLogosMap'
import { StyledCard, StyledImg } from './styles'
import { WholesalerActionType } from '../../types'

type Props = {
   activeWholesaler: WholesalerType
   handleWholesaler: (wholesalerId: string, type: WholesalerActionType) => void
}
// obsluzyc if needCooperationMail => wysyłamy maila o wspolprace z danymi jesli nie to wyswietlamy formularz
export default function NonCoopWholesalerCard({ activeWholesaler, handleWholesaler }: Props) {
   const { t } = useTranslation('WHOLESALERS')
   return (
      <Grid item xs={6} md={4} lg={3}>
         <Tooltip
            title={activeWholesaler.needCooperationMail ? t('sendingMailTooltip') || '' : t('addingCoopTooltip') || ''}
            onClick={
               activeWholesaler.needCooperationMail
                  ? () => {
                       /* tu funkcja do wysyłania maila */
                    }
                  : () => handleWholesaler(activeWholesaler.wholesaleUuid, 'addWholesaler')
            }
         >
            <StyledCard>
               <StyledImg
                  src={wholesalersLogosMap(activeWholesaler.wholesaleName)}
                  alt={activeWholesaler.wholesaleName}
               />
            </StyledCard>
         </Tooltip>
      </Grid>
   )
}
