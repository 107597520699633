import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress, styled } from '@mui/material'
import Grid from '@mui/material/Grid'

import { useLocationsQuery } from 'shared/store/useLocations'
import useLogin from 'shared/store/useLogin'
import { LocationType } from 'shared/store/useLocations/types'
import { UserRoles } from 'shared/consts'
import AddNewLocation from './components/AddNewLocation'
import AdminForm from './components/AdminForm'
import UserForm from './components/UserForm'
import Modal from './components/Modal'
import { FormType } from './types'
import { pl, en } from './locales'

const RootStyle = styled('div')(({ theme }) => ({
   [theme.breakpoints.up('md')]: {
      display: 'flex',
   },
}))

export default function Locations() {
   const { data } = useLocationsQuery()
   const { user } = useLogin()
   const { i18n } = useTranslation('LOCATIONS')
   i18n.addResourceBundle('pl-PL', 'LOCATIONS', pl)
   i18n.addResourceBundle('en-US', 'LOCATIONS', en)
   const [open, setOpen] = useState(false)
   const [modalType, setModalType] = useState<FormType>()
   const [activeLocation, setActiveLocation] = useState<LocationType>()

   function openModal(type: FormType) {
      if (!type) return
      setOpen(true)
      setModalType(type)
   }

   const handleOnChangeLocation = (uuid: string) => {
      if (data) {
         const newActiveLocation = data.find((item) => item.uuid === uuid)
         setActiveLocation(newActiveLocation)
      }
   }
   useEffect(() => {
      if (data) {
         setActiveLocation(data[0])
      }
   }, [data])

   return (
      <RootStyle>
         {activeLocation ? (
            <Grid container spacing={3} sx={{ my: 1 }}>
               <AddNewLocation
                  active={activeLocation}
                  onChangeLocation={handleOnChangeLocation}
                  list={data}
                  openModal={() => openModal('newLocation')}
               />
               {user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) ? (
                  <AdminForm values={activeLocation} openModal={() => openModal('changePassword')} />
               ) : (
                  <UserForm values={activeLocation} />
               )}
            </Grid>
         ) : (
            <div style={{ width: '100%', textAlign: 'center' }}>
               <CircularProgress color="primary" />
            </div>
         )}
         <Modal open={open} locationUuid={activeLocation?.uuid} type={modalType} handleClose={() => setOpen(false)} />
      </RootStyle>
   )
}
