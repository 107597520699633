import Button from '@mui/material/Button'
import React from 'react'
import { Link } from 'react-router-dom'
import useLogin from 'shared/store/useLogin'

function Homepage(): JSX.Element {
   const { logout, user } = useLogin()
   return (
      <div>
         <h1>dashboard homepage</h1>
         <Link to="/">Go to login page</Link>
         <br />
         <Link to="settings">Go to settings</Link>
         <Button variant="contained" onClick={() => logout()}>
            Logout
         </Button>
      </div>
   )
}

export default Homepage
