export const settings = {
   defaultLang: 'pl-PL',
   fallbackLang: 'pl-PL',
}

export const locales = [
   {
      prefix: 'pl-PL',
      name: 'Polski',
   },
   {
      prefix: 'en-US',
      name: 'English',
   },
]
