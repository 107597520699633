import React from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'

import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'
import AdminDetails from './components/AdminDetails'
import UserDetails from './components/UserDetails'
import UploadLogoAdmin from './components/UploadLogoAdmin'
import UploadLogoUser from './components/UploadLogoUser'
import { en, pl } from './locales'

export default function Profile() {
   const { i18n } = useTranslation(['TRANSLATION'])
   const { user } = useLogin()
   i18n.addResourceBundle('pl-PL', 'PROFILE', pl)
   i18n.addResourceBundle('en-US', 'PROFILE', en)
   return (
      <Grid container spacing={3} sx={{ my: 1 }}>
         <Grid item xs={12} md={3}>
            <Card sx={{ p: 3, textAlign: 'center' }}>
               {user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) ? <UploadLogoAdmin /> : <UploadLogoUser />}
            </Card>
         </Grid>

         <Grid item xs={12} md={9}>
            <Card sx={{ p: 3, m: 2, mt: 3 }}>
               {user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) ? <AdminDetails /> : <UserDetails />}
            </Card>
         </Grid>
      </Grid>
   )
}
