import { Yup } from 'shared/lib'

const validName = /^([a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄ'-]\s?)*([^\s\d][a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄ])$/
const validTelephoneNumber = /^[0-9]{9}$/
const validTaxID = /^\d{10}$/
const validCompanyName = /^([a-zA-Z0-9żźćńółęąśŻŹŚŁĆŃÓĘĄ'",-][.]?[\s]?)*([a-zA-Z0-9żźćńółęąśŻŹŚŁĆŃÓĘĄ]\.?)$/
const validPostCode = /^[0-9]{2}-[0-9]{3}$/
const validCity = /^([a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄ",-][.]?[\s]?)*([^\d][a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄ",-]\.?)$/
const validPassword = /^(?=.*[0-9])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*()]{6,32}$/
const validSmsCode = /\b\d{6}\b/
// Schemas:
export const firstStepSchema = Yup.object().shape({
   firstName: Yup.string().min(3).max(32).matches(validName).required(),
   lastName: Yup.string().min(3).max(32).matches(validName).required(),
   email: Yup.string().min(3).max(32).email().required(),
   telephoneNumber: Yup.string().matches(validTelephoneNumber, 'validation.telephoneNumberPattern').required(),
})

export const secondStepSchema = Yup.object().shape(
   {
      taxID: Yup.string().required().matches(validTaxID),
      companyName: Yup.string().max(128).matches(validCompanyName).required(),
      street: Yup.string().max(100).required(),
      houseNumber: Yup.string().max(15).required(),
      postCode: Yup.string().matches(validPostCode).required(),
      city: Yup.string().matches(validCity).required(),
      shop: Yup.boolean().when(['workshop'], {
         is: false,
         then: Yup.boolean().oneOf([true], 'validation.atLeastOneField'),
      }),
      workshop: Yup.boolean().when(['shop'], {
         is: false,
         then: Yup.boolean().oneOf([true], 'validation.atLeastOneField'),
      }),
      workshopEmployees: Yup.number().when('workshop', {
         is: true,
         then: Yup.number().min(1).required(),
      }),
      shopEmployees: Yup.number().when('shop', {
         is: true,
         then: Yup.number().min(1).required(),
      }),
   },
   [
      ['shop', 'workshop'], // <--- adding your fields which need validation
   ]
)

export const thirdStepSchema = Yup.object().shape({
   password: Yup.string().matches(validPassword, 'validation.passwordPattern').required(),
   confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref('password'), null], 'validation.confirmPassword'),
   smsCode: Yup.string().required().matches(validSmsCode, { message: 'validation.smsCode', excludeEmptyString: true }),
})
