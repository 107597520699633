import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikProvider, useFormik } from 'formik'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

import InputField from 'shared/components/InputField'

import { useProfileMutation, useProfileQuery } from 'shared/store/useProfile'
import { UpdateUserProfilePayload, UserDataType } from 'shared/store/useProfile/types'
import CustomSnackbar from 'shared/components/CustomSnackbar'

import schema from './schema'

export default function UserDetails() {
   const { t } = useTranslation(['TRANSLATION', 'PROFILE'])
   const { data } = useProfileQuery<UserDataType>()
   const [displaySnackbar, setDisplaySnackbar] = useState(false)
   const { mutate, error: mutationError, isError: isMutationError, isLoading, isSuccess } = useProfileMutation()

   const formik = useFormik<UserDataType>({
      initialValues: {
         uuid: '',
         name: '',
         locationUuid: '',
         surname: '',
         phoneNumber: '',
         email: '',
      },
      validationSchema: schema,
      onSubmit: handleSubmit,
   })
   const { setValues, values } = formik
   function handleSubmit() {
      const newSettings: UpdateUserProfilePayload = {
         type: 'user',
         uuid: values.uuid,
         phoneNumber: values.phoneNumber,
         locationUuid: values.locationUuid,
         name: values.name,
         surname: values.surname,
         email: values.email,
      }

      mutate(newSettings)
      setDisplaySnackbar(true)
   }
   useEffect(() => {
      if (data) {
         setValues({ ...data })
      }
   }, [data])

   return (
      <FormikProvider value={formik}>
         <Form>
            <Stack spacing={{ xs: 2, md: 3 }}>
               <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                  {t('PROFILE:contactDetails')}
               </Typography>
               <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <InputField name="name" required fullWidth label={t('TRANSLATION:formFields.name')} />
                  <InputField
                     name="phoneNumber"
                     InputProps={{
                        startAdornment: <InputAdornment position="start">+48</InputAdornment>,
                     }}
                     required
                     fullWidth
                     label={t('TRANSLATION:formFields.phoneNumber')}
                  />
               </Stack>
               <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <InputField name="surname" required fullWidth label={t('TRANSLATION:formFields.surname')} />
                  <InputField name="email" required fullWidth disabled label={t('TRANSLATION:formFields.email')} />
               </Stack>
               <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <Button disabled={isLoading} type="submit" sx={{ color: 'white' }} variant="contained">
                     {t('save')}
                  </Button>
               </Box>
            </Stack>
         </Form>
         <CustomSnackbar
            open={displaySnackbar}
            alertDetails={{
               alertType: isSuccess ? 'success' : 'error',
               alertMessage: mutationError ? t('PROFILE:failedSave') : t('PROFILE:successSave'),
            }}
            handleClose={() => setDisplaySnackbar(false)}
         />
      </FormikProvider>
   )
}
