import FingerprintJS from '@fingerprintjs/fingerprintjs'

export async function getFingerprint() {
   const fingerPrintFromLs = localStorage.getItem('FINGERPRINT')
   if (fingerPrintFromLs) {
      return fingerPrintFromLs
   }
   const fpPromise = await FingerprintJS.load()
      .then((fp) => fp.get())
      .then((res) => {
         localStorage.setItem('FINGERPRINT', res.visitorId)
         return res.visitorId
      })
   return fpPromise
}
