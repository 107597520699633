import isEqual from 'lodash/isEqual'
import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'
import { fetchProfileData, saveProfileData } from './services'

import { AdminDataType, UpdateProfilePayload, UpdateProfileResult, UserDataType } from './types'

async function getUserProfileData() {
   const request = await fetchProfileData().then((response) => ({
         uuid: response.uuid,
         name: response.name,
         surname: response.surname,
         email: response.email,
         locationUuid: response.locationUuid,
         phoneNumber: response.phoneNumber,
         companyName: response.companyName,
         country: response.address.country,
         city: response.address.city,
         street: response.address.street,
         zipCode: response.address.zipCode,
         houseNumber: response.address.houseNumber,
         taxID: response.taxID,
         workshopPositions: response.workshopPositions,
         workshop: response.workshop,
         shopPositions: response.shopPositions,
         shop: response.shop,
         image: response.image,
      }))

   return request
}

export function useProfileQuery<T>(): UseQueryResult<T> {
   return useQuery(['userProfile'], getUserProfileData, { staleTime: 3600000 })
}

export function useProfileMutation() {
   const queryClient = useQueryClient()

   return useMutation<UpdateProfileResult, unknown, UpdateProfilePayload, unknown>(
      (payload) => {
         if (payload.type === 'admin') {
            const initialData = queryClient.getQueryData<AdminDataType>('userProfile')
            const initialPayload = initialData
               ? {
                    type: 'admin',
                    uuid: initialData.uuid,
                    name: initialData.name,
                    surname: initialData.surname,
                    email: initialData.email,
                    phoneNumber: initialData.phoneNumber,
                    companyName: initialData.companyName,
                    taxID: initialData.taxID,
                    address: {
                       country: initialData.country,
                       city: initialData.city,
                       houseNumber: initialData.houseNumber,
                       street: initialData.street,
                       zipCode: initialData.zipCode,
                    },
                    typeService: {
                       shop: initialData.shop,
                       workshop: initialData.workshop,
                       shopPositions: initialData.shopPositions,
                       workshopPositions: initialData.workshopPositions,
                    },
                 }
               : {}

            if (isEqual(initialPayload, payload)) {
               throw new Error('Nothing is changed')
            } else {
               return saveProfileData(payload)
            }
         } else {
            const initialData = queryClient.getQueryData<UserDataType>('userProfile')
            const initialPayload = initialData
               ? {
                    type: 'user',
                    uuid: initialData.uuid,
                    name: initialData.name,
                    locationUuid: initialData.locationUuid,
                    surname: initialData.surname,
                    email: initialData.email,
                    phoneNumber: initialData.phoneNumber,
                 }
               : {}
            if (isEqual(initialPayload, payload)) {
               throw new Error('Nothing is changed')
            } else {
               return saveProfileData(payload)
            }
         }
      },
      {
         onSuccess: (data) => {
            if (data.status === 200) {
               queryClient.invalidateQueries('userProfile')
            }
         },
      }
   )
}
