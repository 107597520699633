import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import { styled } from '@mui/material/styles'

import loginImage from 'assets/images/illustration_login.png'
import loginComponents from 'assets/images/illustration_components.png'
import loginInvite from 'assets/images/illustration_invite.png'
import Box from '@mui/material/Box'

const SlideWrapper = styled('div')(() => ({
   height: 500,
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
}))

const SliderDot = styled('button')<{ active: string }>(({ theme, active }) => ({
   width: 12,
   height: 12,
   fontSize: 10,
   borderRadius: '100%',
   border: 'none',
   backgroundColor: active === 'true' ? theme.palette.primary.dark : theme.palette.primary.main,
   lineHeight: 1,
   margin: '0 5px',
   cursor: 'pointer',
}))

const SLIDER_CONFIG = {
   autoPlay: true,
   showArrows: false,
   showStatus: false,
   infiniteLoop: true,
   showThumbs: false,
   renderIndicator: (
      clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
      isSelected: boolean,
      index: number,
      label: string
   ) => <SliderDot active={String(isSelected)} onClick={clickHandler} title={label} key={index} />,
}
function HomepageSlider() {
   return (
      <Carousel {...SLIDER_CONFIG}>
         <SlideWrapper>
            <Box component="img" src={loginImage} alt="login" sx={{ maxWidth: '50%' }} />
         </SlideWrapper>
         <SlideWrapper>
            <Box component="img" src={loginComponents} alt="login" sx={{ maxWidth: '50%' }} />
         </SlideWrapper>
         <SlideWrapper>
            <Box component="img" src={loginInvite} alt="login" sx={{ maxWidth: '50%' }} />
         </SlideWrapper>
      </Carousel>
   )
}

export default HomepageSlider
