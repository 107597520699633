import React from 'react'
import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import { useTheme } from '@mui/material'

import NewLocationForm from '../NewLocationForm'
import ChangePasswordForm from '../ChangePasswordForm'

interface Props {
   open: boolean
   type: 'newLocation' | 'changePassword' | undefined
   handleClose: () => void
   locationUuid?: string
}

export default function Modal({ open, type, handleClose, locationUuid }: Props) {
   const { t } = useTranslation('TRANSLATION')
   const theme = useTheme()

   function renderForm() {
      switch (type) {
         case 'newLocation':
            return <NewLocationForm handleClose={handleClose} />
         case 'changePassword':
            return <ChangePasswordForm locationUuid={locationUuid} handleClose={handleClose} />
         default:
            return null
      }
   }
   return (
      <Dialog open={open} onClose={handleClose}>
         <DialogTitle>
            <Typography sx={{ color: theme.palette.primary.main, fontSize: 20 }}>{t(`LOCATIONS:${type}`)}</Typography>
         </DialogTitle>
         <DialogContent>{renderForm()}</DialogContent>
      </Dialog>
   )
}
