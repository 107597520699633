import React, { useState } from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'

import { httpClient } from 'shared/lib'
import { getFingerprint, isAxiosError } from 'shared/utils/helpers'
import { FirstStep, SecondStep, ThirdStep } from './Steps'
import { firstStepSchema, secondStepSchema, thirdStepSchema } from './schemas'

type ValidateSmsCodeResponse = {
   valid: boolean
}

type RegisterResponse = {
   body: {
      accessToken: string
   }
}

type RegisterErrorResponse = {
   errors: { code: string }[]
}

export default function RegisterForm() {
   const { t } = useTranslation(['REGISTER', 'GLOBALS'])
   const [activeStep, setActiveStep] = useState(0)
   const [registerErrors, setRegisterErrors] = useState<string[]>([])
   const schemas = [firstStepSchema, secondStepSchema, thirdStepSchema]
   const steps = [t('REGISTER:stepper.step1'), t('REGISTER:stepper.step2'), t('REGISTER:stepper.step3')]
   const isLastStep = activeStep === steps.length - 1
   const formik = useFormik<RegisterInitialValues>({
      initialValues: {
         email: '',
         password: '',
         confirmPassword: '',
         firstName: '',
         lastName: '',
         houseNumber: '',
         companyName: '',
         telephoneNumber: '',
         city: '',
         street: '',
         postCode: '',
         taxID: '',
         workshopEmployees: '',
         shopEmployees: '',
         afterSubmit: '',
         shop: false,
         workshop: false,
         smsCode: '',
      },
      validationSchema: schemas[activeStep],
      onSubmit: handleSubmit,
   })

   async function validateSmsCode() {
      const { smsCode } = formik.values
      const { data, status } = await httpClient.post<ValidateSmsCodeResponse>('sms/verify', { smsCode })
      if (status === 200) {
         return data.valid
      }
      return false
   }

   async function register() {
      const { values } = formik
      const hashedPassword = Buffer.from(values.password).toString('base64')
      const fingerprint = await getFingerprint()
      setRegisterErrors([])

      httpClient
         .post<RegisterResponse>(
            'user/',
            {
               acceptRegulations: true,
               address: {
                  city: values.city,
                  country: 'Polska',
                  houseNumber: values.houseNumber,
                  street: values.street,
                  zipCode: values.postCode,
                  // voivodeship: ,
                  // county: ,
               },
               companyName: values.companyName,
               email: values.email,
               password: hashedPassword,
               rePassword: hashedPassword, // for what ?
               name: values.firstName,
               phoneNumber: values.telephoneNumber,
               reCaptcha: '123456',
               surname: values.lastName,
               taxID: values.taxID,
               typeService: {
                  shop: values.shop,
                  shopPositions: values.shopEmployees,
                  workshop: values.workshop,
                  workshopPositions: values.workshopEmployees,
               },
               // userUuid,
            },
            { headers: { 'X-Fingerprint': fingerprint } }
         )
         .then((res) => console.log(1, 'REGISTER SUCCESS', res))
         .catch((err) => {
            if (isAxiosError<RegisterErrorResponse>(err)) {
               if (Array.isArray(err.response?.data.errors)) {
                  const errors = err.response
                     ? err.response.data.errors.map((error) => t(`REGISTER:errors.${error.code}`))
                     : []
                  setRegisterErrors(errors)
               } else {
                  setRegisterErrors([t('GLOBALS:apiErrors.500')])
               }
            }
         })
   }

   function handleSubmit() {
      formik.validateForm().then(async (errors) => {
         const isValidForm = Object.keys(errors).length === 0
         if (isValidForm) {
            console.log('SUBMIT')
            const smsCodeIsValid = await validateSmsCode()

            if (smsCodeIsValid) {
               register()
            } else {
               formik.setFieldError('smsCode', 'REGISTER:errors.wrongSmsCode')
               console.log('UPS, wrong sms code')
            }
         }
      })
   }

   function goStepBack() {
      const lastStep = activeStep - 1
      if (!formik.isValid) {
         formik.setErrors({})
      }
      setActiveStep(lastStep)
   }

   function goStepNext() {
      formik.validateForm().then((errors) => {
         const isValidForm = Object.keys(errors).length === 0
         if (isValidForm) {
            const nextStep = activeStep + 1
            setActiveStep(nextStep)
         }
      })
   }

   function renderStepContent(step: number) {
      switch (step) {
         case 0:
            return <FirstStep />
         case 1:
            return <SecondStep />
         case 2:
            return <ThirdStep />
         default:
            return <div>{t('GLOBALS:notFound')}</div>
      }
   }

   console.log(1, 'FORMIK_ERRORS', formik.errors)
   return (
      <FormikProvider value={formik}>
         <Form>
            <Stepper activeStep={activeStep} sx={{ mb: 2 }}>
               {steps.map((label) => (
                  <Step key={label}>
                     <StepLabel sx={{ fontWeight: 'bold' }}>{label}</StepLabel>
                  </Step>
               ))}
            </Stepper>

            {renderStepContent(activeStep)}

            <Stack direction="row" justifyContent="space-between">
               <FormControl sx={{ mt: 2, ml: 3, mb: 2 }} variant="standard">
                  {activeStep !== 0 ? (
                     <Button onClick={goStepBack} variant="contained" sx={{ mt: 1, mb: 1 }}>
                        <ArrowBackIosNewOutlinedIcon sx={{ fontSize: 24, color: 'white' }} />
                     </Button>
                  ) : null}
               </FormControl>
               <FormControl sx={{ m: 2, mr: 2 }} variant="standard">
                  {!isLastStep ? (
                     <Button
                        onClick={goStepNext}
                        variant="contained"
                        sx={{ mt: 1, mb: 1, color: 'white' }}
                        disabled={!formik.isValid}
                     >
                        <ArrowForwardIosOutlinedIcon sx={{ fontSize: 24, color: 'white' }} />
                     </Button>
                  ) : (
                     <Button
                        variant="contained"
                        onClick={handleSubmit}
                        sx={{ mt: 1, mb: 1, color: 'white' }}
                        disabled={!formik.isValid}
                     >
                        {t('REGISTER:mainSite.endRegistration')}
                     </Button>
                  )}
               </FormControl>
            </Stack>
            <Box sx={{ my: 3 }}>
               {registerErrors.length
                  ? registerErrors.map((error) => (
                       <Alert key={error} severity="error">
                          {error}
                       </Alert>
                    ))
                  : null}
            </Box>
         </Form>
      </FormikProvider>
   )
}
