import React from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Tooltip from '@mui/material/Tooltip'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

import { LocationType } from 'shared/store/useLocations/types'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'

interface Props {
   active?: LocationType
   openModal: () => void
   list?: LocationType[]
   onChangeLocation: (id: string) => void
}

export default function AddNewLocation({ openModal, list = [], onChangeLocation, active }: Props) {
   const { t } = useTranslation('LOCATIONS')
   const { user } = useLogin()

   return (
      <Grid item xs={12} md={3}>
         <Card sx={{ textAlign: 'center', p: 3, m: 2, mt: 3 }}>
            {user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) ? (
               <Tooltip placement="top" title={t('newLocation') || ''}>
                  <Fab color="primary" onClick={openModal}>
                     <AddIcon sx={{ color: 'white', fontSize: 35 }} />
                  </Fab>
               </Tooltip>
            ) : null}
            <Box
               sx={{
                  mt: user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) ? 3 : 0,
                  ml: 2,
                  display: 'flex',
                  justifyContent: 'flex-start',
               }}
            >
               <Stack>
                  {list?.map((location) => (
                        <Button
                           key={location.uuid}
                           sx={{ justifyContent: 'start', color: location.uuid === active?.uuid ? 'main' : 'GrayText' }}
                           onClick={() => onChangeLocation(location.uuid)}
                        >
                           {location.locationName}
                        </Button>
                     ))}
               </Stack>
            </Box>
         </Card>
      </Grid>
   )
}
