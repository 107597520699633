import { useQuery, UseQueryResult } from 'react-query'
import { fetchAwardedProducers } from './services'
import { FetchAwardedProducersResults, AwardedProducers } from './types'

async function getAwardedProducers() {
   const request = await fetchAwardedProducers().then((response) => response.awardedProducers.map((producer) => ({
            uuid: producer.uuid,
            name: producer.name,
            imageFilePath: producer.imageFilePath,
            visible: producer.visible,
            position: producer.position,
         })))

   return request
}

export default function useAwardedProducers(): UseQueryResult<AwardedProducers> {
   return useQuery('awardedProducers', getAwardedProducers, { staleTime: 3600000 })
}
