import React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { Container } from '@mui/material'

import { en, pl } from './locales'

const StyledLine = styled('hr')(() => ({
   width: '100%',
   opacity: 0.3,
}))

const RootStyle = styled('div')(() => ({
   textAlign: 'center',
   justifyContent: 'center',
   marginTop: '40px',
   minHeight: 'calc(100vh - 64px - 87px)',
}))

export default function PrivacyPolicy() {
   const { t, i18n } = useTranslation('PRIVACYPOLICY')
   i18n.addResourceBundle('pl-PL', 'PRIVACYPOLICY', pl)
   i18n.addResourceBundle('en-US', 'PRIVACYPOLICY', en)

   return (
      <RootStyle>
         <Typography variant="h4">{t('title')} </Typography>
         <StyledLine />
         <Typography sx={{ mt: '10px' }} variant="h6">
            {t('processingOfPersonalDataLabel.label')}
         </Typography>
         <Container sx={{ textAlign: 'left', mt: '20px' }}>
            <ol>
               <li style={{ marginTop: '8px' }}>
                  <Typography variant="body1">{t('processingOfPersonalDataLabel.point_1')}</Typography>
               </li>
               <li style={{ marginTop: '8px' }}>
                  <Typography variant="body1">{t('processingOfPersonalDataLabel.point_2')}</Typography>
               </li>
               <li style={{ marginTop: '8px' }}>
                  <Typography variant="body1">{t('processingOfPersonalDataLabel.point_3')}</Typography>
               </li>
               <li style={{ marginTop: '8px' }}>
                  <Typography variant="body1">{t('processingOfPersonalDataLabel.point_4')}</Typography>
               </li>
               <li style={{ marginTop: '8px' }}>
                  <Typography variant="body1">{t('processingOfPersonalDataLabel.point_5')}</Typography>
               </li>
               <li style={{ marginTop: '8px' }}>
                  <Typography variant="body1">{t('processingOfPersonalDataLabel.point_6')}</Typography>
               </li>
               <li style={{ marginTop: '8px' }}>
                  <Typography variant="body1">{t('processingOfPersonalDataLabel.point_7')}</Typography>
               </li>
               <li style={{ marginTop: '8px' }}>
                  <Typography variant="body1">{t('processingOfPersonalDataLabel.point_8_main')}</Typography>
                  <ol type="a">
                     <li style={{ marginTop: '5px' }}>
                        <Typography variant="body1">{t('processingOfPersonalDataLabel.point_8_a')}</Typography>
                     </li>
                     <li style={{ marginTop: '5px' }}>
                        <Typography variant="body1">{t('processingOfPersonalDataLabel.point_8_b')}</Typography>
                     </li>
                     <li style={{ marginTop: '5px' }}>
                        <Typography variant="body1">{t('processingOfPersonalDataLabel.point_8_c')}</Typography>
                     </li>
                     <li style={{ marginTop: '5px' }}>
                        <Typography variant="body1">{t('processingOfPersonalDataLabel.point_8_d')}</Typography>
                     </li>
                     <li style={{ marginTop: '5px' }}>
                        <Typography variant="body1">{t('processingOfPersonalDataLabel.point_8_e')}</Typography>
                     </li>
                     <li style={{ marginTop: '5px' }}>
                        <Typography variant="body1">{t('processingOfPersonalDataLabel.point_8_f')}</Typography>
                     </li>
                  </ol>
               </li>
            </ol>
         </Container>
         <Typography sx={{ mt: '50px' }} variant="h6">
            {t('tTheBasicOfTheProcessing.label')}
         </Typography>
         <Container sx={{ textAlign: 'left', mt: '20px' }}>
            <Typography sx={{ mt: '10px' }} variant="body1">
               {t('theBasicOfTheProcessing.content_label')}
            </Typography>
            <ol type="a">
               <li style={{ marginTop: '8px' }}>
                  <Typography variant="body1">{t('theBasicOfTheProcessing.content_point_a')}</Typography>
               </li>
               <li style={{ marginTop: '8px' }}>
                  <Typography variant="body1">{t('theBasicOfTheProcessing.content_point_b')}</Typography>
               </li>
            </ol>
         </Container>
         <Typography sx={{ mt: '50px' }} variant="h6">
            {t('categoriesOfRecipients.label')}
         </Typography>
         <Container sx={{ textAlign: 'left', mt: '20px' }}>
            <ol type="a">
               <li style={{ marginTop: '8px' }}>
                  <Typography variant="body1">{t('categoriesOfRecipients.content_point_a')}</Typography>
               </li>
               <li style={{ marginTop: '8px' }}>
                  <Typography variant="body1">{t('categoriesOfRecipients.content_point_b')}</Typography>
               </li>
            </ol>
            <Typography variant="body1">{t('categoriesOfRecipients.content_part_3')}</Typography>
         </Container>
         <Typography sx={{ mt: '50px' }} variant="h6">
            {t('usersRights.label')}
         </Typography>
         <Container sx={{ textAlign: 'left', mt: '20px' }}>
            <ol>
               <li style={{ marginTop: '8px' }}>
                  <Typography variant="body1">{t('usersRights.point_1')}</Typography>
               </li>
               <li style={{ marginTop: '8px' }}>
                  <Typography variant="body1">{t('usersRights.point_2')}</Typography>
               </li>
               <li style={{ marginTop: '8px' }}>
                  <Typography variant="body1">{t('usersRights.point_3')}</Typography>
               </li>
               <li style={{ marginTop: '8px' }}>
                  <Typography variant="body1">{t('usersRights.point_4')}</Typography>
               </li>
               <li style={{ marginTop: '8px' }}>
                  <Typography variant="body1">{t('usersRights.point_5')}</Typography>
               </li>
               <li style={{ marginTop: '8px' }}>
                  <Typography variant="body1">{t('usersRights.point_6')}</Typography>
               </li>
               <li style={{ marginTop: '8px' }}>
                  <Typography variant="body1">{t('usersRights.point_7')}</Typography>
               </li>
               <li style={{ marginTop: '8px' }}>
                  <Typography variant="body1">{t('usersRights.point_8')}</Typography>
               </li>
               <li style={{ marginTop: '8px' }}>
                  <Typography variant="body1">
                     {t('usersRights.point_9')}
                     <a href="mailto:kontakt@motorro.pl">kontakt@motorro.pl</a>
                  </Typography>
               </li>
            </ol>
         </Container>
      </RootStyle>
   )
}
