import React from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

const RootStyle = styled(Card)(({ theme }) => ({
   maxWidth: 480,
   minHeight: 580,
   margin: 'auto',
   display: 'flex',
   position: 'relative',
   alignItems: 'center',
   flexDirection: 'column',
   padding: theme.spacing(3),
   [theme.breakpoints.up(414)]: {
      padding: theme.spacing(5),
   },
}))
interface Props {
   card: {
      subscription: string
      price: number
      caption: string
      labelAction: string
      lists: {
         text: string
      }[]
   }
}

export default function PricingPlanCard({ card }: Props) {
   const { subscription, price, caption, lists, labelAction } = card
   const { t } = useTranslation('PRICING')

   return (
      <RootStyle>
         <Typography variant="h5" sx={{ color: 'text.secondary' }}>
            {subscription}
         </Typography>

         <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
            <Typography variant="h2" sx={{ mx: 1 }}>
               {price}
            </Typography>
            <Typography
               gutterBottom
               component="span"
               variant="subtitle2"
               sx={{
                  alignSelf: 'flex-end',
                  color: 'text.secondary',
               }}
            >
               {t('currency')}
            </Typography>
         </Box>

         <Typography
            variant="caption"
            sx={{
               color: 'primary.dark',
               textTransform: 'capitalize',
            }}
         >
            {t(`${caption}`)}
         </Typography>

         <Stack component="ul" spacing={2} sx={{ my: 5, width: 1 }}>
            {lists.map((item) => (
               <Stack
                  key={item.text}
                  component="li"
                  direction="row"
                  alignItems="center"
                  spacing={1.5}
                  sx={{ typography: 'body2' }}
               >
                  <Typography variant="body2">{t(`${item.text}`)}</Typography>
               </Stack>
            ))}
         </Stack>

         <Button
            sx={{ color: 'white', position: 'absolute', bottom: 25, width: '65%' }}
            size="large"
            variant="contained"
         >
            {t(`${labelAction}`)}
            <ArrowForwardIosIcon sx={{ fontSize: 22, fontWeight: 12, marginLeft: 2 }} />
         </Button>
      </RootStyle>
   )
}
