import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'

import WatchedProduct from './components/WatchedProduct'
import { pl, en } from './locales'

export default function Watchlist() {
   const { t, i18n } = useTranslation('WATCHLIST')
   i18n.addResourceBundle('pl-PL', 'WATCHLIST', pl)
   i18n.addResourceBundle('en-US', 'WATCHLIST', en)
   return (
      <Card sx={{ pb: 3, px: 3, pt: 3, m: 2, mt: 6 }}>
         <Typography variant="h4" sx={{ pb: 3, color: 'text.secondary', fontWeight: 'normal' }}>
            {t('watchlistTitle')}
         </Typography>
         <WatchedProduct index="gdb1330" producer="trw" name="klocki hamulcowe audi a3" date="10-02-2022" />
      </Card>
   )
}
