import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikProvider, useFormik } from 'formik'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { styled } from '@mui/material'

import InputField from 'shared/components/InputField'
import { AddLocationPayload } from 'shared/store/useLocations/types'
import { useAddLocationMutation } from 'shared/store/useLocations'

import CustomSnackbar from 'shared/components/CustomSnackbar'
import { newLocationSchema } from './schema'

interface Props {
   handleClose: () => void
}

const StyledButton = styled(Button)({
   textTransform: 'uppercase',
   fontWeight: 'normal',
})

export default function NewLocationForm({ handleClose }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'LOCATIONS'])
   const [displaySnackbar, setDisplaySnackbar] = useState(false)
   const { mutate, isError: isMutationError, isSuccess } = useAddLocationMutation()

   const formik = useFormik<NewLocationInitialValues>({
      initialValues: {
         locationName: '',
         email: '',
         name: '',
         surname: '',
         country: '',
         houseNumber: '',
         phoneNumber: '',
         city: '',
         street: '',
         zipCode: '',
      },
      validationSchema: newLocationSchema,
      onSubmit: handleSubmit,
   })

   const { values } = formik
   function handleSubmit() {
      const newSettings: AddLocationPayload = {
         city: values.city,
         locationName: values.locationName,
         email: values.email,
         name: values.name,
         // ask Mariusz/Seba how country works in our app because in old front there is a country from endpoint || "Poland"
         country: 'Polska',
         surname: values.surname,
         houseNumber: values.houseNumber,
         phoneNumber: values.phoneNumber,
         street: values.street,
         zipCode: values.zipCode,
      }
      mutate(newSettings, {
         onSettled: () => setDisplaySnackbar(true),
      })
   }
   return (
      <div>
         <FormikProvider value={formik}>
            <Form>
               <Typography variant="body1" sx={{ mt: 1, mb: 4, color: 'text.secondary' }}>
                  {t('LOCATIONS:newLocationDialogContent')}
               </Typography>
               <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
                  <InputField
                     name="locationName"
                     required
                     variant="standard"
                     label={t('TRANSLATION:formFields.locationName')}
                  />
                  <InputField name="city" required variant="standard" label={t('TRANSLATION:formFields.city')} />
                  <InputField name="zipCode" required variant="standard" label={t('TRANSLATION:formFields.postCode')} />
               </Stack>
               <Stack direction={{ xs: 'column', md: 'row' }} sx={{ mt: 3 }} spacing={3}>
                  <InputField name="street" required variant="standard" label={t('TRANSLATION:formFields.street')} />
                  <InputField
                     name="houseNumber"
                     required
                     variant="standard"
                     label={t('TRANSLATION:formFields.houseNumber')}
                  />
                  <InputField
                     InputProps={{
                        startAdornment: <InputAdornment position="start">+48</InputAdornment>,
                     }}
                     name="phoneNumber"
                     required
                     variant="standard"
                     label={t('TRANSLATION:formFields.phoneNumber')}
                  />
               </Stack>
               <Stack direction={{ xs: 'column', md: 'row' }} sx={{ mt: 3 }} spacing={3}>
                  <InputField name="name" required variant="standard" label={t('TRANSLATION:formFields.name')} />
                  <InputField name="surname" required variant="standard" label={t('TRANSLATION:formFields.surname')} />
                  <InputField name="email" required variant="standard" label={t('TRANSLATION:formFields.email')} />
               </Stack>
               <Box sx={{ width: '100%', display: 'flex', pt: 5, justifyContent: 'flex-end', alignContent: 'bottom' }}>
                  <StyledButton onClick={handleClose} variant="text">
                     {t('TRANSLATION:cancel')}
                  </StyledButton>
                  <StyledButton type="submit" variant="text">
                     {t('TRANSLATION:save')}
                  </StyledButton>
               </Box>
            </Form>
         </FormikProvider>
         <CustomSnackbar
            open={displaySnackbar}
            alertDetails={{
               alertType: isSuccess ? 'success' : 'error',
               alertMessage: isMutationError ? t('TRANSLATION:failedSave') : t('TRANSLATION:successSave'),
            }}
            handleClose={() => setDisplaySnackbar(false)}
         />
      </div>
   )
}
