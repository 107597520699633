import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik, FormikProvider, Form } from 'formik'
import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

import changePasswordService from 'shared/services/changePassword'
import CustomSnackbar from 'shared/components/CustomSnackbar'
import InputField from 'shared/components/InputField'
import useLogin from 'shared/store/useLogin'

import { changePasswordSchema } from './schema'

interface Props {
   locationUuid?: string
   handleClose: () => void
}

export default function ChangePasswordForm({ locationUuid, handleClose }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'LOCATIONS'])
   const [displaySnackbar, setDisplaySnackbar] = useState(false)
   const [isSuccessfullyChanged, setIsSuccessfullyChanged] = useState(false)
   const { user } = useLogin()

   const formik = useFormik<ChangePasswordInitialValues>({
      initialValues: {
         password: '',
         confirmPassword: '',
      },
      validationSchema: changePasswordSchema,
      onSubmit: handleSubmit,
   })

   const { values } = formik

   function handleSubmit() {
      const newSettings: ChangePasswordPayloadType = {
         locationUuid,
         password: btoa(values.password),
         repassword: btoa(values.confirmPassword),
         userUuid: user.uuid,
      }
      changePasswordService(newSettings)
         .then((res) => {
            if (res.status === 200) {
               setIsSuccessfullyChanged(true)
            }
         })
         .catch((err) => {
            if (err) {
               setIsSuccessfullyChanged(false)
            }
         })
         .finally(() => setDisplaySnackbar(true))
   }

   const StyledButton = styled(Button)({
      textTransform: 'uppercase',
      fontWeight: 'normal',
   })

   return (
      <div>
         <FormikProvider value={formik}>
            <Form>
               <Typography variant="body1" sx={{ mt: 1, mb: 4, color: 'text.secondary' }}>
                  {t('LOCATIONS:changePasswordDialog.text')}
               </Typography>
               <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
                  <InputField
                     type="password"
                     name="password"
                     required
                     variant="standard"
                     label={t('TRANSLATION:formFields.password')}
                  />
                  <InputField
                     type="password"
                     name="confirmPassword"
                     required
                     variant="standard"
                     label={t('TRANSLATION:formFields.confirmPassword')}
                  />
               </Stack>
               <Box sx={{ width: '100%', display: 'flex', pt: 5, justifyContent: 'flex-end', alignContent: 'bottom' }}>
                  <StyledButton onClick={handleClose} variant="text">
                     {t('TRANSLATION:cancel')}
                  </StyledButton>
                  <StyledButton type="submit" variant="text">
                     {t('TRANSLATION:save')}
                  </StyledButton>
               </Box>
            </Form>
         </FormikProvider>
         <CustomSnackbar
            open={displaySnackbar}
            alertDetails={{
               alertType: isSuccessfullyChanged ? 'success' : 'error',
               alertMessage: isSuccessfullyChanged ? t('TRANSLATION:successSave') : t('TRANSLATION:failedSave'),
            }}
            handleClose={() => setDisplaySnackbar(false)}
         />
      </div>
   )
}
