export enum LocalStorageKeys {
   ACCESS_TOKEN = 'ACCESS_TOKEN',
   REFRESH_TOKEN = 'REFRESH_TOKEN',
   EXPIRES_IN = 'EXPIRES_IN',
}

export enum UserRoles {
   MAIN_LOCALIZATION_ADMIN = 'MAIN_LOCALIZATION_ADMIN',
   PREMIUM = 'PREMIUM',
   REGISTERED_USER = 'REGISTERED_USER',
   SUBSCRIPTION = 'SUBSCRIPTION',
}
