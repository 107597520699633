import { Yup } from 'shared/lib'

const validName = /^([a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄ'-]\s?)*([^\s\d][a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄ])$/
const validTelephoneNumber = /^[0-9]{9}$/
const validPostCode = /^[0-9]{2}-[0-9]{3}$/
const validCity = /^([a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄ",-][.]?[\s]?)*([^\d][a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄ",-]\.?)$/
// Schemas:
export const newLocationSchema = Yup.object().shape({
   locationName: Yup.string().min(3).max(32).required(),
   name: Yup.string().min(3).max(32).matches(validName).required(),
   surname: Yup.string().min(3).max(32).matches(validName).required(),
   phoneNumber: Yup.string().matches(validTelephoneNumber, 'validation.telephoneNumberPattern').required(),
   street: Yup.string().max(100).required(),
   houseNumber: Yup.string().max(15).required(),
   country: Yup.string(),
   zipCode: Yup.string().matches(validPostCode).required(),
   city: Yup.string().matches(validCity).required(),
   email: Yup.string().min(3).max(32).email().required(),
})
