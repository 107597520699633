import { httpClient } from 'shared/lib'

import { ReflinkSettingsType, UpdateReflinkSettingsPayload } from './types'

export function fetchReflinkSettingsData(): Promise<ReflinkSettingsType> {
   return httpClient
      .get('user/getReflinkSettings')
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch((err) => {
         console.log(0, 'ERROR FROM fetchReflinkSettingsData()', err)
         throw new Error('apiErrors.apiProblem')
      })
}

export function saveReflinkSettings(payload: UpdateReflinkSettingsPayload) {
   return httpClient
      .put('/user/saveReflinkSettings', payload)
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         console.log(0, 'ERROR FROM saveReflinkSettings()', err)
         throw new Error('apiErrors.apiProblem')
      })
}
