import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'

import { useProfileQuery } from 'shared/store/useProfile'
import { AdminDataType } from 'shared/store/useProfile/types'
import { RootStyle, DropZoneStyle, PlaceholderStyle, StyledInput, StyledImage } from './styles'

export default function UploadLogoAdmin() {
   const { t } = useTranslation('PROFILE')
   const { data } = useProfileQuery<AdminDataType>()
   const [fileError, setFileError] = useState('')
   const [logoPreview, setLogoPreview] = useState('')

   function handleLogoChange(e: any) {
      if (e.target.files[0]) {
         if (
            e.target.files[0].type === 'image/png' ||
            e.target.files[0].type === 'image/jpg' ||
            e.target.files[0].type === 'image/jpeg'
         ) {
            if (e.target.files[0].size < 1024 * 512) {
               setLogoPreview(URL.createObjectURL(e.target.files[0]))
               setFileError('')
            } else {
               setFileError('sizeError')
            }
         } else {
            setFileError('typeError')
         }
      }
   }

   return (
      <div>
         <RootStyle>
            <Tooltip placement="top" title={t('clickToAddLogoLabel') || ''}>
               <DropZoneStyle>
                  <PlaceholderStyle>
                     <StyledInput onChange={(e) => handleLogoChange(e)} type="file" />
                     <AddAPhotoIcon sx={{ width: 32, height: 32, position: 'relative', bottom: 80 }} />
                  </PlaceholderStyle>
                  {data?.image ? (
                     <StyledImage src={logoPreview || `data:image/png;base64,${data?.image}`} alt="" />
                  ) : null}
               </DropZoneStyle>
            </Tooltip>
         </RootStyle>
         <Typography variant="body2" sx={{ color: 'text.secondary', mt: 0.8 }}>
            {t('allowedLabel')}
            <br />
            {t('maxSizeLabel')}
         </Typography>
         {fileError === 'sizeError' && (
            <Alert onClose={() => setFileError('')} severity="error">
               {t('sizeError')}
            </Alert>
         )}
         {fileError === 'typeError' && (
            <Alert onClose={() => setFileError('')} severity="error">
               {t('typeError')}
            </Alert>
         )}
      </div>
   )
}
