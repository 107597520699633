import axios, { AxiosRequestConfig } from 'axios'
import { LocalStorageKeys } from 'shared/consts'

export const httpClient = axios.create({
   baseURL: process.env.REACT_APP_API_URL,
   // httpsAgent: new https.Agent({
   // 	rejectUnauthorized: false,
   // }),

   headers: {
      Accept: 'application/json',
   },
})

httpClient.interceptors.request.use((config: AxiosRequestConfig) => {
   const token = localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN)
   let authHeader
   if (token !== null) {
      authHeader = {
         'X-Authorization': `Bearer ${token}`,
      }
      return {
         ...config,
         headers: { ...config.headers, ...authHeader },
      }
   }
   return config
})
