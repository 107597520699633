import React from 'react'
import { useFormik, Form, FormikProvider } from 'formik'
import { useTranslation } from 'react-i18next'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

import { Yup } from 'shared/lib'
import useLogin from 'shared/store/useLogin'

const VALIDATION_SCHEMA = Yup.object().shape({
   email: Yup.string().email().required(),
})

type InitialValues = {
   email: string
}

export default function RemindPasswordForm() {
   const { t } = useTranslation(['TRANSLATION', 'HOMEPAGE'])
   const { isLoading } = useLogin()
   const formik = useFormik<InitialValues>({
      initialValues: {
         email: '',
      },
      validationSchema: VALIDATION_SCHEMA,
      onSubmit: () => console.log('reminding password'),
   })
   const { errors, touched, handleSubmit, getFieldProps } = formik

   return (
      <FormikProvider value={formik}>
         <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
               <TextField
                  fullWidth
                  autoComplete="username"
                  type="email"
                  label={t('TRANSLATION:formFields.email')}
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email && t(errors.email)}
               />
            </Stack>

            <Button
               fullWidth
               size="large"
               type="submit"
               variant="contained"
               disabled={Boolean(errors.email) || isLoading}
               sx={{ color: 'white', mt: 4 }}
            >
               {t('HOMEPAGE:remindForm.remind')}
            </Button>
         </Form>
      </FormikProvider>
   )
}
