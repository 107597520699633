import { httpClient } from 'shared/lib'
import { FetchSettingsResult, UpdateSettingsPayloadType } from './types'

export function fetchSettingsData(): Promise<FetchSettingsResult> {
   return httpClient
      .get('user/preferences/getAll')
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch((err) => {
         console.log(0, 'ERROR FROM fetchSettingsData()', err)
         throw new Error('apiErrors.apiProblem')
      })
}

export function saveSettingsData(payload: UpdateSettingsPayloadType) {
   return httpClient
      .post('/user/preferences/saveAll', payload)
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         console.log(0, 'ERRROR FROM saveSettingsData()', err)
         throw new Error('apiErrors.apiProblem')
      })
}
