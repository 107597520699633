import { Yup } from 'shared/lib'

const schema = Yup.object().shape({
   times: Yup.array()
      .of(
         Yup.object().shape({
            time: Yup.string().required(),
            weekDay: Yup.string().oneOf(['MONDAY_FRIDAY', 'SATURDAY']).required(),
         })
      )
      .required()
      .min(1, 'WHOLESALERS:minEditTimesError')
      .max(10, 'WHOLESALERS:maxEditTimesError'),
})
export default schema
