import React from 'react'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import { StandardTextFieldProps } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import FormHelperText from '@mui/material/FormHelperText'

interface Props extends StandardTextFieldProps {
   name: string
   label: string
   options: {
      name: string
      value: string
   }[]
}

function SelectField({ name, label, options }: Props) {
   const { t } = useTranslation('SETTINGS')
   const [fieldProps, meta] = useField(name)
   return (
      <FormControl error={!!meta.error} component="fieldset" variant="standard" sx={{ width: '260px' }}>
         <InputLabel>{label}</InputLabel>

         <Select value={fieldProps.value} onChange={fieldProps.onChange} name={name}>
            {options.map((option) => (
               <MenuItem sx={{ height: 35 }} key={option.value} value={option.value}>
                  {option.name}
               </MenuItem>
            ))}
         </Select>
         <FormHelperText>{meta.error && t(meta.error)}</FormHelperText>
      </FormControl>
   )
}
export default SelectField
