import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { FormikProvider, Form, useFormik } from 'formik'

import { AdminDetailsType, LocationType, SaveAdminDetailsPayload } from 'shared/store/useLocations/types'
import { useAdminDetailsLocationMutation } from 'shared/store/useLocations'
import InputField from 'shared/components/InputField'
import CustomSnackbar from 'shared/components/CustomSnackbar'

import { adminDetailsSchema } from './schema'

interface Props {
   openModal: () => void
   values?: LocationType
}
export default function AdminDetailsForm({ openModal, values }: Props) {
   const { t } = useTranslation(['LOCATIONS', 'TRANSLATION'])
   const { mutate, isError: isMutationError, isSuccess } = useAdminDetailsLocationMutation()
   const [displaySnackbar, setDisplaySnackbar] = useState(false)

   const formik = useFormik<AdminDetailsType>({
      initialValues: {
         name: '',
         surname: '',
         email: '',
         phoneNumber: '',
         uuid: '',
         country: '',
         locationUuid: '',
      },
      validationSchema: adminDetailsSchema,
      onSubmit: handleSubmit,
   })
   const { setValues } = formik

   function handleSubmit() {
      const newSettings: SaveAdminDetailsPayload = { ...formik.values }
      mutate(newSettings, {
         onSettled: () => setDisplaySnackbar(true),
      })
   }

   useEffect(() => {
      if (values) {
         setValues({
            name: values.adminFirstName,
            surname: values.adminLastName,
            email: values.adminEmail,
            phoneNumber: values.adminTelephoneNumber,
            uuid: values.adminID,
            locationUuid: values.uuid,
            country: 'Polska',
         })
      }
   }, [values])

   return (
      <FormikProvider value={formik}>
         <Form>
            <Stack>
               <Typography
                  variant="body1"
                  sx={{ fontWeight: 'bold', color: 'text.secondary', textTransform: 'uppercase', mb: 2 }}
               >
                  {t('LOCATIONS:locationAdminDetailsLabel')}
               </Typography>
               <Stack sx={{ pl: 3 }} direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 3 }}>
                  <InputField name="name" variant="standard" required label={t('TRANSLATION:formFields.name')} />
                  <InputField name="surname" variant="standard" required label={t('TRANSLATION:formFields.surname')} />
                  <InputField
                     name="phoneNumber"
                     variant="standard"
                     required
                     label={t('TRANSLATION:formFields.phoneNumber')}
                  />
                  <InputField
                     name="email"
                     variant="standard"
                     disabled
                     required
                     label={t('TRANSLATION:formFields.email')}
                  />
               </Stack>

               <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <Button onClick={openModal} sx={{ color: 'white', mr: 2 }} variant="contained">
                     {t('LOCATIONS:changePassword')}
                  </Button>
                  <Button type="submit" sx={{ color: 'white' }} variant="contained">
                     {t('TRANSLATION:save')}
                  </Button>
               </Box>
            </Stack>
         </Form>
         <CustomSnackbar
            open={displaySnackbar}
            alertDetails={{
               alertType: isSuccess ? 'success' : 'error',
               alertMessage: isMutationError ? t('TRANSLATION:failedSave') : t('TRANSLATION:successSave'),
            }}
            handleClose={() => setDisplaySnackbar(false)}
         />
      </FormikProvider>
   )
}
