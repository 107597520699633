import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import Unauthorized from 'views/Unauthorized'
import Authorized from 'views/Authorized'
import { ThemeConfig } from 'config/theme'
import 'locales/i18n'

import PrivateRoute from 'shared/components/PrivateRoute'
import { UserRoles } from 'shared/consts'
import reportWebVitals from './reportWebVitals'

const queryClient = new QueryClient()

ReactDOM.render(
   <React.StrictMode>
      <ThemeConfig>
         <QueryClientProvider client={queryClient}>
            <BrowserRouter>
               <Routes>
                  <Route path="*" element={<Unauthorized />} />
                  <Route
                     path="dashboard/*"
                     element={
                        <PrivateRoute
                           component={Authorized}
                           roles={[
                              UserRoles.MAIN_LOCALIZATION_ADMIN,
                              UserRoles.PREMIUM,
                              UserRoles.REGISTERED_USER,
                              UserRoles.SUBSCRIPTION,
                           ]}
                        />
                     }
                  />
               </Routes>
            </BrowserRouter>
            <ReactQueryDevtools initialIsOpen />
         </QueryClientProvider>
      </ThemeConfig>
   </React.StrictMode>,
   document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
