import { httpClient } from 'shared/lib'
import { isAxiosError } from 'shared/utils/helpers'
import { AuthorizationResult, LoginPayload, VerificationStatus } from './types'

export async function authorizeUser(payload: LoginPayload): Promise<AuthorizationResult> {
   try {
      const { data } = await httpClient.post<AuthorizationResult>(
         'user/login',
         { username: payload.email, password: payload.password, verificationCode: payload.smsCode },
         { headers: { 'X-Fingerprint': payload.fingerPrint } }
      )

      return data
   } catch (error) {
      if (isAxiosError<{ message: VerificationStatus }>(error)) {
         return {
            accessToken: null,
            freeSubscriptions: 0,
            fingerprintVerificationStatus: error.response?.data?.message || 'INVALID_CREDENTIALS',
         }
      }
      return {
         accessToken: null,
         freeSubscriptions: 0,
         fingerprintVerificationStatus: 'INVALID_CREDENTIALS',
      }
   }
}
