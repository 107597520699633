import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useNavigate } from 'react-router-dom'
import InputAdornment from '@mui/material/InputAdornment'
import OutlinedInput from '@mui/material/OutlinedInput'
import SearchIcon from '@mui/icons-material/Search'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

export const SearchbarStyle = styled('div')(() => ({
   width: '400px',
   display: 'flex',
   alignItems: 'center',
}))

export default function Searchbar() {
   const navigate = useNavigate()
   const [searchValue, setSearchValue] = useState('')
   const { t } = useTranslation('TRANSLATION')

   function handleOnSearch() {
      navigate({
         pathname: '/search',

         search: `?${createSearchParams({
            value: searchValue,
         })}`,
      })
   }

   return (
      <SearchbarStyle>
         <OutlinedInput
            size="small"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            autoFocus
            fullWidth
            placeholder={t('components.searchProduct.placeholder')}
            startAdornment={
               <InputAdornment position="start">
                  <Box component={SearchIcon} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
               </InputAdornment>
            }
            color="primary"
            endAdornment={
               <Button
                  onClick={handleOnSearch}
                  disabled={!searchValue}
                  color="primary"
                  variant="contained"
                  sx={{ height: 40 }}
               >
                  <Box component={SearchIcon} sx={{ color: 'white', width: 20, height: 20 }} />
               </Button>
            }
            sx={{ pr: 0 }}
         />
      </SearchbarStyle>
   )
}
