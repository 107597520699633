import { styled } from '@mui/material/styles'

export const RootStyle = styled('div')(({ theme }) => ({
   width: '100%',
   position: 'sticky',
   top: 0,
   paddingBottom: '3px',
   backgroundColor: theme.palette.background.default,
}))

export const StyledLogo = styled('img')(({ theme }) => ({
   maxHeight: theme.sizes.headerSm,
   maxWidth: 100,
   [theme.breakpoints.up('lg')]: {
      maxWidth: 140,
   },
}))

export const LinksContainer = styled('div')(({ theme }) => ({
   color: theme.palette.primary.dark,
}))

export const LogoUE = styled('img')({
   marginRight: '15px',
   width: '110px',
   marginBottom: '6px',
})

export const LogoFunds = styled('img')({
   width: '80px',
})
