import { Yup } from 'shared/lib'

const validName = /^([a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄ'-]\s?)*([^\s\d][a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄ])$/
const validTelephoneNumber = /^[0-9]{9}$/

export const adminDetailsSchema = Yup.object().shape({
   phoneNumber: Yup.string().matches(validTelephoneNumber, 'validation.telephoneNumberPattern').required(),
   name: Yup.string().min(3).max(32).matches(validName).required(),
   surname: Yup.string().min(3).max(32).matches(validName).required(),
   email: Yup.string(),
})
