import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import { useNavigate, createSearchParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { CircularProgress } from '@mui/material'

import useAwardedProducers from 'shared/store/useAwardedProducers'
import { AwardedProducers } from 'shared/store/useAwardedProducers/types'

const SlideWrapper = styled('div')(() => ({
   height: 96,
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
}))

const SlideBox = styled(Box)(({ theme }) => ({
   height: 96,
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
   margin: 35,
   [theme.breakpoints.down('md')]: {
      margin: 10,
   },
   cursor: 'pointer',
}))

const SLIDER_CONFIG = {
   autoPlay: true,
   showArrows: true,
   showStatus: false,
   infiniteLoop: true,
   showThumbs: false,
   showIndicators: false,
}

export default function ProducersSlider() {
   const { data } = useAwardedProducers()
   const navigate = useNavigate()

   function handleOnSearch(producerName: string) {
      navigate({
         pathname: '/search',

         search: `?${createSearchParams({
            value: producerName,
         })}`,
      })
   }

   function sliceArray(array: AwardedProducers, chunkSize: number) {
      const res = []
      for (let i = 0; i < array.length; i += chunkSize) {
         const chunk = array.slice(i, i + chunkSize)
         res.push(chunk)
      }
      return res
   }

   const wrappers = data ? sliceArray(data, 3) : []

   return (
      <div style={{ textAlign: 'center', boxShadow: '1px 1px 6px -3px #000000', marginTop: 20 }}>
         {data ? (
            <Carousel {...SLIDER_CONFIG}>
               {wrappers.map((wrapper) => (
                  <SlideWrapper>
                     {wrapper.map((element) => (
                        <SlideBox
                           onClick={() => handleOnSearch(element.name)}
                           sx={{
                              '&:hover': {
                                 transition: 'transform .2s',
                                 transform: 'scale(1.2)',
                              },
                           }}
                           key={element.uuid}
                        >
                           <img
                              style={{
                                 width: 80,
                                 height: 'auto',
                              }}
                              src={`https://app.motorro.eu${element.imageFilePath}`}
                              alt={element.name}
                           />
                        </SlideBox>
                     ))}
                  </SlideWrapper>
               ))}
            </Carousel>
         ) : (
            <CircularProgress />
         )}
      </div>
   )
}
