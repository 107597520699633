import React, { useState, useEffect } from 'react'
import { useFormik, Form, FormikProvider } from 'formik'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import Button from '@mui/material/Button'

import { Yup } from 'shared/lib'
import useLogin from 'shared/store/useLogin'
import { AuthorizationResult } from 'shared/store/useLogin/types'

const VALIDATION_SCHEMA = Yup.object().shape({
   email: Yup.string().email().required(),
   password: Yup.string().required(),
})

type InitialValues = {
   email: string
   password: string
   smsCode?: string
}

export default function LoginForm() {
   const { t } = useTranslation(['TRANSLATION', 'HOMEPAGE'])
   const [fingerPrint, setFingerPrint] = useState('')
   const [showPassword, setShowPassword] = useState(false)
   const [hasStartedVerification, setHasStartedVerification] = useState(false)
   const [afterSubmit, setAfterSubmit] = useState<AlertInfo>()
   const navigate = useNavigate()
   const { user, login, error, isLoading } = useLogin()
   const formik = useFormik<InitialValues>({
      initialValues: {
         email: '',
         password: '',
      },
      validationSchema: VALIDATION_SCHEMA,
      onSubmit: async (formValues, { setSubmitting, resetForm }) => {
         try {
            setSubmitting(true)
            setAfterSubmit(undefined)
            const hashedPassword = btoa(formValues.password)
            const payload = {
               email: formValues.email,
               password: hashedPassword,
               fingerPrint,
               smsCode: formValues.smsCode,
            }
            login(payload)
         } catch (err) {
            resetForm()
            setSubmitting(false)
         }
      },
   })
   const { errors, touched, handleSubmit, getFieldProps } = formik

   async function getFingerprint() {
      const fingerPrintFromLs = localStorage.getItem('FINGERPRINT')
      if (fingerPrintFromLs) {
         return fingerPrintFromLs
      }
      const fpPromise = await FingerprintJS.load()
         .then((fp) => fp.get())
         .then((res) => res.visitorId)
      return fpPromise
   }

   function handleShowPassword() {
      setShowPassword((show) => !show)
   }

   useEffect(() => {
      if (!fingerPrint) {
         getFingerprint()
            .then((res) => {
               localStorage.setItem('FINGERPRINT', res)
               setFingerPrint(res)
            })
            .catch((err) => console.log(err))
      }
   }, [])

   useEffect(() => {
      if (error?.message) {
         if (error.message === 'VERIFICATION_CODE_SENT') {
            setHasStartedVerification(true)
         }
         const newAfterSubmit = translateLoginError(error.message)
         setAfterSubmit(newAfterSubmit)
      }
   }, [error?.message])

   useEffect(() => {
      if (user.isLogged) {
         navigate('/dashboard')
      }
   }, [user.isLogged])
   return (
      <FormikProvider value={formik}>
         <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
               {afterSubmit && <Alert severity={afterSubmit.status}>{t(afterSubmit.message)}</Alert>}

               {!hasStartedVerification ? (
                  <>
                     <TextField
                        fullWidth
                        autoComplete="username"
                        type="email"
                        label={t('TRANSLATION:formFields.email')}
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email && t(errors.email)}
                     />

                     <TextField
                        fullWidth
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        label={t('TRANSLATION:formFields.password')}
                        {...getFieldProps('password')}
                        InputProps={{
                           endAdornment: (
                              <InputAdornment position="end">
                                 <IconButton onClick={handleShowPassword} edge="end">
                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                 </IconButton>
                              </InputAdornment>
                           ),
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password && t(errors.password)}
                     />
                  </>
               ) : (
                  <TextField
                     fullWidth
                     type="text"
                     label={t('TRANSLATION:formFields.smsCode')}
                     {...getFieldProps('smsCode')}
                     error={Boolean(touched.smsCode && errors.smsCode)}
                     helperText={touched.smsCode && errors.smsCode && t(errors.smsCode)}
                  />
               )}
            </Stack>

            <Button
               fullWidth
               size="large"
               type="submit"
               variant="contained"
               disabled={Boolean(errors.email || errors.password) || isLoading}
               sx={{ color: 'white', mt: 4 }}
            >
               {t('HOMEPAGE:loginForm.login')}
            </Button>
         </Form>
      </FormikProvider>
   )
}

function translateLoginError(err: PropType<AuthorizationResult, 'fingerprintVerificationStatus'>): AlertInfo {
   switch (err) {
      case 'INVALID_CREDENTIALS':
      case 'Unauthorized':
         return {
            status: 'error',
            message: 'HOMEPAGE:loginForm.invalidCredentials',
         }
      case 'INVALID_VERIFICATION_CODE':
         return {
            status: 'error',
            message: 'HOMEPAGE:loginForm.wrongSmsCode',
         }
      case 'SMS_LIMIT_REACHED':
         return {
            status: 'error',
            message: 'HOMEPAGE:loginForm.smsCodeLimit',
         }
      case 'VERIFICATION_CODE_SENT':
         return {
            status: 'success',
            message: 'HOMEPAGE:loginForm.smsCodeSent',
         }
      default:
         return {
            status: 'error',
            message: 'HOMEPAGE:loginForm.problemWithApi',
         }
   }
}
