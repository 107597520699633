import { Yup } from 'shared/lib'

const validPostCode = /^[0-9]{2}-[0-9]{3}$/
const validCity = /^([a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄ",-][.]?[\s]?)*([^\d][a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄ",-]\.?)$/
// Schemas:

export const locationDetailsSchema = Yup.object().shape({
   street: Yup.string().max(100).required(),
   postCode: Yup.string().matches(validPostCode).required(),
   city: Yup.string().matches(validCity).required(),
   houseNumber: Yup.string().max(15).required(),
   // shippingStreet: Yup.string().max(100).required(),
   // shippingPostCode: Yup.string().matches(validPostCode).required(),
   // shippingCity: Yup.string().matches(validCity).required(),
   // shippingHouseNumber: Yup.string().max(15).required(),
})
