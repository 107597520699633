import React from 'react'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import TextField, { StandardTextFieldProps } from '@mui/material/TextField'

interface Props extends StandardTextFieldProps {
   name: keyof RegisterInitialValues
}

function InputField({ name, ...rest }: Props) {
   const [field, meta] = useField(name)

   const { t } = useTranslation('TRANSLATION')
   return (
      <TextField
         fullWidth
         margin="none"
         error={Boolean(meta.error)}
         helperText={meta.error && t(meta.error)}
         {...field}
         {...rest}
      />
   )
}

export default InputField
