import { httpClient } from 'shared/lib'

type CompanyData = {
   companyName: string
   houseNumber: string
   city: string
   street: string
   postalCode: string
}

type CompanyDataResponse = {
   data?: CompanyData
   error?: string
}

export async function getCompanyData(taxId: string): Promise<CompanyDataResponse> {
   try {
      const { status, data } = await httpClient.get<CompanyData>(`gus/${taxId}`)
      if (status === 200) {
         return { data }
      }
      if (status === 400) {
         return { error: 'REGISTER:errors.taxIdInactive' }
      }
      if (status === 404) {
         return { error: 'REGISTER:errors.taxIdNotFound' }
      }
      if (status === 406) {
         return { error: 'REGISTER:errors.taxIdNotAccepted' }
      }
      return { error: 'TRANSLATION:apiErrors.500' }
   } catch (error) {
      return { error: 'TRANSLATION:apiErrors.500' }
   }
}
