import React from 'react'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import { StandardTextFieldProps } from '@mui/material'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Stack from '@mui/material/Stack'

interface Props extends StandardTextFieldProps {
   name: string
   options: {
      label: string
      value: string
   }[]
}

function RadioField({ options, name }: Props) {
   const { t } = useTranslation()
   const [fieldProps, meta] = useField(name)
   return (
      <FormControl required error={!!meta.error} component="fieldset" variant="standard">
         <RadioGroup {...fieldProps}>
            <Stack direction={{ md: 'row', sx: 'column' }}>
               {options.map((option) => (
                     <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                     />
                  ))}
            </Stack>
         </RadioGroup>
         <FormHelperText>{meta.error && t(meta.error)}</FormHelperText>
      </FormControl>
   )
}
export default RadioField
