import React from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

import { LocationType } from 'shared/store/useLocations/types'

interface Props {
   values?: LocationType
}

export default function UserForm({ values }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'LOCATIONS'])

   return (
      <Grid item xs={12} md={9}>
         {values ? (
            <Card sx={{ p: 3, m: 2, mt: 3 }}>
               <Stack spacing={{ xs: 2, md: 3 }}>
                  <Typography
                     variant="body1"
                     sx={{ fontWeight: 'bold', color: 'text.secondary', textTransform: 'uppercase' }}
                  >
                     {t('LOCATIONS:locationDetailsLabel')}
                  </Typography>
                  <Stack sx={{ pl: 3 }} direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 3 }}>
                     <TextField
                        value={values.locationName}
                        variant="standard"
                        disabled
                        required
                        label={t('TRANSLATION:formFields.locationName')}
                     />
                     <TextField
                        value={values.street}
                        variant="standard"
                        disabled
                        required
                        label={t('TRANSLATION:formFields.street')}
                     />
                     <TextField
                        value={values.houseNumber}
                        variant="standard"
                        disabled
                        required
                        label={t('TRANSLATION:formFields.houseNumber')}
                     />
                  </Stack>
                  <Stack sx={{ pl: 3 }} direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 3 }}>
                     <TextField
                        value={values.postCode}
                        variant="standard"
                        disabled
                        required
                        label={t('TRANSLATION:formFields.postCode')}
                     />
                     <TextField
                        value={values.city}
                        variant="standard"
                        disabled
                        required
                        label={t('TRANSLATION:formFields.city')}
                     />
                     <TextField
                        value={values.country}
                        variant="standard"
                        disabled
                        required
                        label={t('TRANSLATION:formFields.country')}
                     />
                  </Stack>
               </Stack>
               <Stack sx={{ marginTop: 3 }} spacing={{ xs: 2, md: 3 }}>
                  <Typography
                     variant="body1"
                     sx={{ fontWeight: 'bold', color: 'text.secondary', textTransform: 'uppercase' }}
                  >
                     {t('LOCATIONS:shippingInformationsTitle')}
                  </Typography>
                  <Stack sx={{ pl: 3 }} direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 3 }}>
                     <TextField
                        value={values.shippingStreet}
                        variant="standard"
                        disabled
                        required
                        label={t('TRANSLATION:formFields.street')}
                     />
                     <TextField
                        value={values.shippingHouseNumber}
                        variant="standard"
                        disabled
                        required
                        label={t('TRANSLATION:formFields.houseNumber')}
                     />
                     <TextField
                        value={values.shippingCity}
                        variant="standard"
                        disabled
                        required
                        label={t('TRANSLATION:formFields.city')}
                     />
                     <TextField
                        value={values.shippingPostCode}
                        variant="standard"
                        disabled
                        required
                        label={t('TRANSLATION:formFields.postCode')}
                     />
                  </Stack>
               </Stack>
               <Stack sx={{ marginTop: 4 }}>
                  <Typography
                     variant="body1"
                     sx={{ fontWeight: 'bold', color: 'text.secondary', textTransform: 'uppercase' }}
                  >
                     {t('LOCATIONS:locationAdminDetailsLabel')}
                  </Typography>

                  <Stack sx={{ pl: 3, mt: 3 }} direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 3 }}>
                     <TextField
                        value={values.adminFirstName}
                        variant="standard"
                        disabled
                        required
                        label={t('TRANSLATION:formFields.name')}
                     />
                     <TextField
                        value={values.adminLastName}
                        variant="standard"
                        disabled
                        required
                        label={t('TRANSLATION:formFields.surname')}
                     />
                     <TextField
                        value={values.adminTelephoneNumber}
                        variant="standard"
                        disabled
                        required
                        label={t('TRANSLATION:formFields.phoneNumber')}
                     />
                     <TextField
                        value={values.adminEmail}
                        variant="standard"
                        disabled
                        required
                        label={t('TRANSLATION:formFields.email')}
                     />
                  </Stack>
               </Stack>
            </Card>
         ) : null}
      </Grid>
   )
}
