import React from 'react'
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import PersonIcon from '@mui/icons-material/Person'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation'

import { UserRoles } from 'shared/consts'
import { RouteType } from 'shared/types'
import Locations from 'views/Authorized/views/Locations'
import Pricing from 'views/Authorized/views/Pricing'
import Profile from 'views/Authorized/views/Profile'
import Watchlist from 'views/Authorized/views/Watchlist'
import Settings from 'views/Authorized/views/Settings'
import Wholesalers from 'views/Authorized/views/Wholesalers'

const routes: RouteType[] = [
   {
      name: 'routes.profile',
      group: 'settings',
      icon: React.createElement(PersonIcon),
      authority: [UserRoles.MAIN_LOCALIZATION_ADMIN, UserRoles.SUBSCRIPTION],
      path: 'profile',
      element: Profile,
   },
   {
      name: 'routes.payments',
      group: 'settings',
      icon: React.createElement(AttachMoneyOutlinedIcon),
      authority: [UserRoles.MAIN_LOCALIZATION_ADMIN],
      path: 'payments',
      element: Pricing,
   },
   {
      name: 'routes.wholesalers',
      group: 'settings',
      icon: React.createElement(EmojiTransportationIcon),
      authority: [UserRoles.MAIN_LOCALIZATION_ADMIN],
      path: 'wholesalers',
      element: Wholesalers,
   },
   {
      name: 'routes.watchlist',
      group: 'settings',
      icon: React.createElement(SearchOutlinedIcon),
      authority: [UserRoles.MAIN_LOCALIZATION_ADMIN],
      path: 'watchlist',
      element: Watchlist,
   },
   {
      name: 'routes.locations',
      group: 'settings',
      icon: React.createElement(MyLocationOutlinedIcon),
      authority: [UserRoles.MAIN_LOCALIZATION_ADMIN, UserRoles.SUBSCRIPTION],
      path: 'locations',
      element: Locations,
   },
   {
      name: 'routes.settings',
      group: 'settings',
      icon: React.createElement(SettingsOutlinedIcon),
      authority: [UserRoles.MAIN_LOCALIZATION_ADMIN],
      path: 'settings',
      element: Settings,
   },
]

export default routes
