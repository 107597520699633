import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikProvider, useFormik } from 'formik'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'
import FormGroup from '@mui/material/FormGroup'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import InputField from 'shared/components/InputField'
import SwitchField from 'shared/components/SwitchField'

import { useProfileMutation, useProfileQuery } from 'shared/store/useProfile'
import { AdminDataType, UpdateAdminProfilePayload } from 'shared/store/useProfile/types'
import CustomSnackbar from 'shared/components/CustomSnackbar'

import schema from './schema'

export default function AdminDetails() {
   const { t } = useTranslation(['TRANSLATION', 'PROFILE'])
   const [displaySnackbar, setDisplaySnackbar] = useState(false)
   const { data } = useProfileQuery<AdminDataType>()
   const { mutate, error: mutationError, isError: isMutationError, isLoading, isSuccess } = useProfileMutation()
   const formik = useFormik<AdminDataType>({
      initialValues: {
         uuid: '',
         name: '',
         surname: '',
         email: '',
         phoneNumber: '',
         companyName: '',
         country: '',
         city: '',
         street: '',
         taxID: '',
         zipCode: '',
         houseNumber: '',
         workshopPositions: 0,
         workshop: false,
         shopPositions: 0,
         shop: false,
      },
      validationSchema: schema,
      onSubmit: handleSubmit,
   })
   const { values, setValues } = formik
   function handleSubmit() {
      const newSettings: UpdateAdminProfilePayload = {
         type: 'admin',
         uuid: values.uuid,
         name: values.name,
         surname: values.surname,
         email: values.email,
         phoneNumber: values.phoneNumber,
         companyName: values.companyName,
         taxID: values.taxID,
         address: {
            country: values.country,
            city: values.city,
            houseNumber: values.houseNumber,
            street: values.street,
            zipCode: values.zipCode,
         },
         typeService: {
            shop: values.shop,
            workshop: values.workshop,
            shopPositions: values.shopPositions,
            workshopPositions: values.workshopPositions,
         },
      }

      mutate(newSettings)
      setDisplaySnackbar(true)
   }

   useEffect(() => {
      if (data) {
         setValues({
            ...data,
         })
      }
   }, [data])

   return (
      <FormikProvider value={formik}>
         <Form>
            <Stack spacing={{ xs: 2, md: 3 }}>
               <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                  {t('PROFILE:contactDetails')}
               </Typography>
               <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <InputField name="name" required fullWidth label={t('TRANSLATION:formFields.name')} />
                  <InputField name="surname" required fullWidth label={t('TRANSLATION:formFields:surname')} />
                  <InputField name="email" required fullWidth disabled label={t('TRANSLATION:formFields.email')} />
                  <InputField
                     name="phoneNumber"
                     InputProps={{
                        startAdornment: <InputAdornment position="start">+48</InputAdornment>,
                     }}
                     required
                     fullWidth
                     label={t('TRANSLATION:formFields.phoneNumber')}
                  />
               </Stack>
               <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                  {t('PROFILE:billingDetails')}
               </Typography>
               <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <InputField name="companyName" required fullWidth label={t('TRANSLATION:formFields.companyName')} />
                  <InputField name="city" required fullWidth label={t('TRANSLATION:formFields.city')} />
                  <InputField name="street" required fullWidth label={t('TRANSLATION:formFields.street')} />
               </Stack>
               <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <InputField name="taxID" required fullWidth label={t('TRANSLATION:formFields.taxID')} />
                  <InputField name="zipCode" required fullWidth label={t('TRANSLATION:formFields.postCode')} />
                  <InputField name="houseNumber" required fullWidth label={t('TRANSLATION:formFields.houseNumber')} />
               </Stack>
               <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <InputField
                     InputLabelProps={{ shrink: true }}
                     disabled={!values.workshop}
                     name="workshopPositions"
                     sx={{ width: 240 }}
                     required
                     label={t('TRANSLATION:formFields.workshopEmployees')}
                  />
                  <FormGroup>
                     <SwitchField name="workshop" label={t('TRANSLATION:formFields.workshop')} />
                  </FormGroup>
               </Stack>
               <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <InputField
                     InputLabelProps={{ shrink: true }}
                     disabled={!values.shop}
                     name="shopPositions"
                     sx={{ width: 240 }}
                     required
                     label={t('TRANSLATION:formFields.shopEmployees')}
                  />
                  <SwitchField name="shop" label={t('TRANSLATION:formFields.shop')} />
               </Stack>
               <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                  {t('PROFILE:deliveryAdress')}
               </Typography>
               <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <InputField name="city" required fullWidth label={t('TRANSLATION:formFields.city')} />
                  <InputField name="zipCode" required fullWidth label={t('TRANSLATION:formFields.postCode')} />
                  <InputField name="street" required fullWidth label={t('TRANSLATION:formFields.street')} />
                  <InputField name="houseNumber" required fullWidth label={t('TRANSLATION:formFields.houseNumber')} />
               </Stack>
               <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <Button disabled={isLoading} type="submit" sx={{ color: 'white' }} variant="contained">
                     {t('save')}
                  </Button>
               </Box>
            </Stack>
         </Form>
         <CustomSnackbar
            open={displaySnackbar}
            alertDetails={{
               alertType: isSuccess ? 'success' : 'error',
               alertMessage: mutationError ? t('TRANSLATION:failedSave') : t('TRANSLATION:successSave'),
            }}
            handleClose={() => setDisplaySnackbar(false)}
         />
      </FormikProvider>
   )
}
