import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { Link as RouterLink } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

import LanguagePopover from 'shared/components/LanguagePopover'
import SearchProduct from 'shared/components/SearchProduct'
import logo from 'assets/images/logo.png'
import { RootStyle, StyledLogo } from './styles'

export default function Header() {
   const { t } = useTranslation('UNAUTHORIZED')
   const theme = useTheme()

   return (
      <RootStyle>
         <Container style={{ minWidth: '100%' }}>
            <Stack direction="row" alignItems="center">
               <a href="/" style={{ marginRight: 1, color: 'text.primary' }}>
                  <StyledLogo src={logo} alt="logo" />
               </a>
               <Box sx={{ flexGrow: 1 }} />
               <SearchProduct />
               <Box sx={{ flexGrow: 1 }} />{' '}
               <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                  <RouterLink
                     style={{ marginLeft: '40px', textDecoration: 'none', color: theme.palette.primary.dark }}
                     to="register"
                  >
                     {t('header.registerTitle')}
                  </RouterLink>

                  <LanguagePopover />
               </Stack>
            </Stack>
         </Container>
      </RootStyle>
   )
}
