import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

import { LocationType } from 'shared/store/useLocations/types'

interface Props {
   active?: LocationType
   list?: LocationType[]
   onChangeLocation: (uuid: string) => void
}

export default function LocationList({ list = [], onChangeLocation, active }: Props) {
   return (
      <Grid item xs={12} md={3}>
         <Card sx={{ textAlign: 'center', p: 3, m: 2, mt: 3 }}>
            <Box
               sx={{
                  ml: 2,
                  display: 'flex',
                  justifyContent: 'flex-start',
               }}
            >
               <Stack>
                  {list?.map((location) => (
                        <Button
                           key={location.uuid}
                           sx={{ justifyContent: 'start', color: location.uuid === active?.uuid ? 'main' : 'GrayText' }}
                           onClick={() => onChangeLocation(location.uuid)}
                        >
                           {location.locationName}
                        </Button>
                     ))}
               </Stack>
            </Box>
         </Card>
      </Grid>
   )
}
