import { Yup } from 'shared/lib'

const schema = Yup.object().shape({
   loginParameter: Yup.string().required(),
   passwordParameter: Yup.string().required(),
   additionalParameter: Yup.string(),
   firstDepartment: Yup.string().required(),
   secondDepartment: Yup.string(),
   thirdDepartment: Yup.string(),
   deliveryDepartment: Yup.string().required(),
   logisticMinimums: Yup.number().required(),
   fv: Yup.boolean(),
   wz: Yup.boolean(),
})

export default schema
