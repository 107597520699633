import { httpClient } from 'shared/lib'

type Result = {
   status: number
}

export default function changePasswordService(payload: ChangePasswordPayloadType) {
   return httpClient
      .put<Result>('/user/changePassword', {
         locationUuid: payload.locationUuid,
         password: payload.password,
         repassword: payload.repassword,
         userUuid: payload.userUuid,
      })
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         console.log(0, 'ERROR FROM ChangePasswordService()', err)
         throw new Error('apiErrors.apiProblem')
      })
}
