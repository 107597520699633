/* eslint-disable no-console */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import SearchIcon from '@mui/icons-material/Search'
import Button from '@mui/material/Button'

import InputField from 'shared/components/InputField'
import SwitchField from 'shared/components/SwitchField'
import { getCompanyData } from '../../../services'

export function SecondStep() {
   const { t } = useTranslation('TRANSLATION')
   const { setFieldError, setValues, values, errors } = useFormikContext<RegisterInitialValues>()
   const taxId = values.taxID
   const isError = !!errors.taxID

   async function handleOnSearch() {
      await getCompanyData(taxId)
         .then((res) => {
            if (res.error) {
               setFieldError('taxID', res.error)
            }
            if (res.data) {
               setValues({
                  ...values,
                  houseNumber: res.data.houseNumber,
                  companyName: res.data.companyName,
                  city: res.data.city,
                  street: res.data.street,
                  postCode: res.data.postalCode,
               })
            }
         })
         .catch((err) => console.log('ERROR from handleOnSearch() in NipForm', err))
   }

   return (
      <Container>
         <Grid container sx={{ marginBottom: 4 }}>
            <Grid item xs={12}>
               <InputField
                  label={t('formFields.taxID')}
                  name="taxID"
                  InputProps={{
                     endAdornment: (
                        <Button
                           onClick={handleOnSearch}
                           color="primary"
                           variant="contained"
                           sx={{ height: 40, ml: 1.5 }}
                           disabled={isError}
                        >
                           <Box component={SearchIcon} sx={{ color: 'white', width: 20, height: 20 }} />
                        </Button>
                     ),
                  }}
               />
            </Grid>
         </Grid>

         <Grid container sx={{ marginBottom: 4 }} justifyContent="space-between">
            <Grid item xs={12}>
               <InputField label={t('formFields.companyName')} name="companyName" />
            </Grid>
         </Grid>
         <Grid container sx={{ marginBottom: 4 }} justifyContent="space-between">
            <Grid item xs={6}>
               <InputField label={t('formFields.city')} name="city" />
            </Grid>
            <Grid item xs={5}>
               <InputField label={t('formFields.postCode')} name="postCode" />
            </Grid>
         </Grid>
         <Grid container sx={{ marginBottom: 4 }} justifyContent="space-between">
            <Grid item xs={6}>
               <InputField label={t('formFields.street')} name="street" />
            </Grid>
            <Grid item xs={5}>
               <InputField label={t('formFields.houseNumber')} name="houseNumber" />
            </Grid>
         </Grid>
         <Grid container sx={{ marginBottom: 4 }} justifyContent="space-between" alignItems="center">
            <Grid item xs={6}>
               <SwitchField name="workshop" label={t('formFields.workshop')} />
            </Grid>
            <Grid item xs={5}>
               <InputField
                  disabled={!values.workshop}
                  label={t('formFields.workshopEmployees')}
                  name="workshopEmployees"
               />
            </Grid>
         </Grid>
         <Grid container sx={{ marginBottom: 4 }} justifyContent="space-between" alignItems="center">
            <Grid item xs={6}>
               <SwitchField name="shop" label={t('formFields.shop')} />
            </Grid>
            <Grid item xs={5}>
               <InputField disabled={!values.shop} label={t('formFields.shopEmployees')} name="shopEmployees" />
            </Grid>
         </Grid>
      </Container>
   )
}
