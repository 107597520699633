import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'

import { settings } from 'config/languages'

import pl from './pl-PL.json'
import en from './en-US.json'

const resources = {
   'pl-PL': {
      TRANSLATION: pl,
   },
   'en-US': {
      TRANSLATION: en,
   },
}

i18n.use(initReactI18next).init({
   resources,
   lng: settings.defaultLang,
   fallbackLng: settings.fallbackLang,
   interpolation: {
      escapeValue: false,
   },
   debug: process.env.NODE_ENV === 'development',
})

export default i18n
